<template>
  <div
    class="container"
    style="margin-top: 48px; margin-bottom: 48px;">
    <div class="DetailsPage__Content">
      <div
        class="ContactVdBlock"
        style="margin-left: auto;margin-right: auto;">
        <div class="col-md-12">
          <h1 class="title_faq">Terms and Conditions</h1>

          <ul class="nav nav-tabs faq">
            <li class="active faq_li">
              <a
                data-toggle="tab"
                href="#rent"
                class="faq_link">Rent-a-car</a>
            </li>
            <li class="faq_li">
              <a
                data-toggle="tab"
                href="#buy"
                class="faq_link">Buy-a-car</a>
            </li>
          </ul>

          <div class="tab-content faq_tabs">
            <div
              id="rent"
              class="tab-pane active">
              <h3 class="headinng_sec_2">Terms and Conditions for Fleet Management Rental Agreement (Staff)</h3>
              <ol
                class="terms_ol"
                style="margin-top:40px">
                <li><span>Interpretation and Definitions</span>
                  <ol>
                    <li>In this Agreement, unless inconsistent with the context:
                      <ol>
                        <li>
                          <span>“Account”</span> means the account opened in our books in respect of this Agreement;
                        </li>
                        <li>
                          <span>“Agent”</span> means a third party appointed by the Bank to facilitate the physical inspection of and delivery of the Vehicle to the Customer;
                        </li>
                        <li>
                          <span>“Agreement”</span> means the rental schedule <span>(Part A)</span> of this agreement, attached to and read together with these terms and conditions <span>(Part B)</span> and all letters and notices relating to same;
                        </li>
                        <li>
                          <span>“Applicable Laws”</span> means all national, provincial, local and municipal legislation or subordinate legislation, ordinances, regulations, by-laws, rules and/or other laws of any relevant regulatory authority and any other instrument having the force of law as may be issued and in force from time to time as well as common law, all as relating to or connected with the activities contemplated under this Agreement and as updated from time to time;
                        </li>
                        <li>
                          <span>“Bank”</span> means The Standard Bank of South Africa Limited (Registration number 1962/000738/06) a public company duly incorporated with limited liability according to the company laws of the Republic of South Africa and/or its successors in title or assigns;
                        </li>
                        <li>
                          <span>“Business Day(s)”</span> mean any day(s) other than a Saturday, Sunday or statutory holiday in the Republic of South Africa;
                        </li>
                        <li>
                          <span>“Collateral”</span> means any security provided to us to secure the repayment of the monthly rentals;
                        </li>
                        <li>
                          <span>“Collateral Provider”</span> means each person and/or entity who is to provide Collateral to the Bank in respect of the due performance by you of your payment and other obligations in terms of this Agreement and Collateral Providers means all of them as the context may indicate;
                        </li>
                        <li>
                          <span>“CPA”</span> means the Consumer Protection Act 68 of 2008 and all regulations promulgated in terms of this act, as amended from time to time;
                        </li>
                        <li>
                          <span>“Employee Rental Scheme Portal”</span> means the online website utilised by the Bank for the display of the Vehicles;
                        </li>
                        <li>
                          <span>“Fair Wear and Tear Guide”</span> means the guide attached to this Agreement as Annexure B;
                        </li>
                        <li>
                          <span>“FICA”</span> means the Financial Intelligence Centre Act 38 of 2001 and all regulations promulgated in terms of this act, as amended from time to time;
                        </li>
                        <li>
                          <span>“Natural Person”</span> means a private individual for purposes of this definition
                        </li>
                        <li>
                          <span>“Parties”</span> means the Bank and the Customer and Party means either one of them;
                        </li>
                        <li>
                          <span>“Personal Information”</span> means information about an identifiable, natural person and where applicable, a juristic person, including, but not limited to information about: race; gender; sex; pregnancy; marital status; nationality; ethnic or social origin; colour; sexual orientation; age; physical or mental health; well-being; disability; religion; conscience; belief; culture; language; birth; education; medical, financial, criminal or employment history; any identifying number, symbol,  e-mail, postal or physical address, telephone number; location;  any online identifier; any other particular assignment to the person; biometric information; personal opinions, views or preferences of the person or the views or opinions of another individual about the person; correspondence sent by the person that is implicitly  or explicitly of a private or confidential nature or further correspondence that would reveal the contents of the original correspondence; and the name of the person if it appears with other personal information relating to the person or if the disclosure of the name itself would reveal information about the person.
                        </li>
                        <li>
                          <span>“Prime Rate”</span> means the publicly quoted overdraft rate of interest of the Bank from time to time as certified by any manager of the Bank, whose authority need not be proved and such certification will be binding in the absence of an obvious error;
                        </li>
                        <li>
                          <span>“Private Use”</span> means the use of the vehicle in any way other than for gain or remuneration;
                        </li>
                        <li>
                          <span>“Process”</span> means any operation or activity, automated or not, concerning Personal Information, including: alteration, blocking, collation, collection, consultation, degradation, destruction, dissemination by means of transmission, distribution or making available in any other form, erasure, linking, merging, organisation, receipt, recording, retrieval, storage, updating, modification, or the use of information. <span>Processing</span> and <span>Processed</span> will have a similar meaning;
                        </li>
                        <li>
                          <span>“Prohibited Activities”</span> means illegal or terrorist activities, money laundering or any activities which are subject to Sanctions or are not in compliance with Applicable Laws;
                        </li>
                        <li>
                          <span>“Sanctions”</span> means any restrictions set by a Sanctioning Body, including but not limited to diplomatic, travel, trade or financial sanctions or embargoes;
                        </li>
                        <li>
                          <span>Sanctioning Body</span> means the United Nations Security Council <span>(UNSC)</span>, the Office of Foreign Assets Control of the Department of Treasury of the United States of America <span>(OFAC)</span>, the European Union <span>(EU)</span>, Her Majesty’s Treasury <span>(HMT)</span>, the Ministry of Economy, Finance and Industry (France) <span>(MINEFI)</span> or any other sanctioning body we recognise from time to time
                        </li>
                        <li><span>“Sanctioned Entity”</span> means:
                          <ol>
                            <li>
                              any natural or juristic person or country;
                            </li>
                            <li>
                              in the case of a juristic person, any person who (i) owns or controls it; or (ii) it owns or controls (and for these purposes, owns means holding any percentage of ownership or beneficial interest and controls means the ability, directly or indirectly and whether through the voting of shares, the appointment of directors or similar officers or through any other means, to control the business or policy of the juristic person);
                            </li>
                            <li>
                              in the case of any country, its ministries, departments, agencies or any other governmental organisations, listed on any Sanctions List or who is subject to any Sanctions;
                            </li>
                          </ol>
                        </li>
                        <li>
                          <span>“Sanctions List”</span> means any list of Sanctioned Entities published by a Sanctioning Body, as updated from time to time;
                        </li>
                        <li>
                          <span>“Standard Bank Group”</span> means Standard Bank Group Limited and its subsidiaries and their subsidiaries;
                        </li>
                        <li>
                          <span>“Statement”</span> means the document reflecting the transactions made on your Account up to the date of the statement; the full amount due to us (including any legally permissible interest, costs, fees and charges that may be levied); the rental; and the payment due date;
                        </li>
                        <li>
                          <span>“VAT”</span> means the value added tax charged in terms of the VAT Act;
                        </li>
                        <li>
                          <span>“VAT Act”</span> means the Value Added Tax Act 89 of 1991 together with all regulations promulgated in terms of this act, as amended from time to time;
                        </li>
                        <li>
                          <span>“Vehicle”</span> means the vehicle more fully described in Part A of this Agreement;
                        </li>
                        <li>
                          <span>“we”, “us”</span> or <span>“our”</span> means the Bank and its transferees;
                        </li>
                        <li>
                          <span>“you”</span> or <span>“your”</span> means the Customer;
                        </li>
                        <li>Any reference to:
                          <ol>
                            <li>
                              the singular includes the plural and vice versa;
                            </li>
                            <li>
                              one gender includes the other genders;
                            </li>
                            <li>
                              Natural Persons includes juristic persons and vice versa; “person” includes a firm, body corporate, unincorporated association or any other body or entity;
                            </li>
                          </ol>
                        </li>
                        <li>
                          The term “including” means “including, but not limited to” and “includes” shall bear a corresponding meaning.
                        </li>
                      </ol>
                    </li>
                    <li>Headings shall not be taken into account in the interpretation hereof.</li>
                    <li>The expiry or termination of this Agreement will not affect those terms that expressly state that they will operate after expiry or termination, or those that of necessity must continue to have effect after expiry or termination, even if the clauses themselves do not expressly state this.</li>
                  </ol>
                </li>
                <li><span>RENTAL</span><br>We hereby rent the Vehicle to you and you hire the Vehicle from us on the terms and conditions set out in this Agreement.
                </li>
                <li><span>OWNERSHIP AND RISK</span>
                  <ol>
                    <li>
                      We shall at all times during and after termination of this Agreement remain the owner of the Vehicle.
                    </li>
                    <li>
                      Subject to the CPA, the risk in and to the Vehicle, including but not limited to liability for loss and damage to and arising from the Vehicle and/or the use, possession or operation of the Vehicle, will pass to you on the date on which you sign the authority to release the Vehicle and this Agreement and shall remain with you until the Vehicle is returned to us.
                    </li>
                  </ol>
                </li>
                <li><span>DELIVERY</span>
                  <ol>
                    <li>
                      You confirm that you have identified and selected the Vehicle from the Employee Rental Scheme Portal.
                    </li>
                    <li>
                      You further confirm that you have been allowed the opportunity to physically inspect the Vehicle at our selected Agent to satisfy yourself with the condition of the Vehicle.
                    </li>
                    <li>
                      You must at your cost obtain and take delivery of the Vehicle from the selected Agent at the agreed place of delivery.
                    </li>
                    <li>
                      Delivery or tender of delivery of the Vehicle within 30 days of the commencement date will be regarded as delivery of the Vehicle by us to you.
                    </li>
                    <li>
                      By signing the Agreement, you agree to the parameters relating to the term/period of the Agreement as well as the kilometre use as specified in Part A
                    </li>
                    <li>Before signing the authority to release the Vehicle, you must:
                      <ol>
                        <li>
                          conduct a comprehensive pre-delivery inspection of the Vehicle to reasonably confirm that the Vehicle is in good order and condition as per the condition report on the Employee Rental Scheme Portal and that there are no reasonably detectable defects in the Vehicle before taking delivery of the Vehicle; and
                        </li>
                        <li>
                          accept the Vehicle, by signing the authority to release the Vehicle; and
                        </li>
                        <li>
                          subject to the CPA, by signing the authority to release the Vehicle and the Agreement you agree that no warranties or representations have been made or given by us as to the state, condition or fitness of the Vehicle; and
                        </li>
                        <li>
                          ensure that the odometer, tachograph or other distance/usage measuring device fitted to the Vehicle (“the meter”) is sealed.
                        </li>
                      </ol>
                    </li>
                  </ol>
                </li>
                <li><span>PAYMENT</span>
                  <ol>
                    <li>
                      You must pay the rentals on the due dates set out in Part A to this Agreement and at such address as we nominate or in terms of clause 5.4 below. To the maximum extent permitted in law, all payments in terms of this Agreement must be made without deductions of any kind, and must be free of exchange, bank costs or other charges.
                    </li>
                    <li>
                      Your payment obligations are unconditional and, to the extent permitted in law, are not subject to set-off or reduction for any reason. You may also not delay or withhold payments due to us on the basis of any claim which you may have against us. If you fail to pay the rentals on the due date, any outstanding amount will attract interest as specified in clause 9.7 below.
                    </li>
                    <li>
                      If we cancel this Agreement and you dispute the cancellation, you must continue to pay all amounts on the due date while you remain in possession of the Vehicle. Acceptance of your payments will not in any way affect any rights we may have against you. If the dispute is decided or settled in our favour, we may keep any amounts paid by you after cancellation of the Agreement as compensation for the losses we have incurred because you did not return the Vehicle on cancellation.
                    </li>
                    <li>
                      You will make payments by debit order. You authorise and instruct us to draw against your bank account, wherever it may be, all payments and amounts due in terms of this Agreement.
                    </li>
                    <li>
                      If the rental is linked to the Prime Rate, we may adjust the rentals payable by you on or after the date of a change in the Prime Rate or if our costs increase due to a change in or the introduction of any law or regulation or a change in the interpretation thereof. We may also adjust the rentals due to changes in VAT rates from time to time.
                    </li>
                    <li>
                      We may at any time apply any amount paid by you in terms hereof to pay any other debt you may have with us. You waive the right to name the debt to which any payment will be applied.
                    </li>
                  </ol>
                </li>
                <li><span>USE OF THE VEHICLE</span>
                  <ol>
                    <li>You must:
                      <ol>
                        <li>
                          ensure that the terms and conditions of any insurance policy in respect of the Vehicle are not contravened.
                        </li>
                        <li>
                          ensure that the Vehicle is not used for any unlawful purpose and, unless otherwise agreed to in writing, that the Vehicle is for “on-road” standard use only.
                        </li>
                        <li>
                          ensure that the Vehicle is only used with due skill and care and in accordance with the manufacturer’s specifications.
                        </li>
                        <li>
                          the Vehicle may not be used to transport persons or property for reward or for driving tuition or hire without our prior written consent.
                        </li>
                        <li>
                          at your own cost, ensure compliance with any laws in respect of the hire, possession, operation, licensing, registration, insurance and use of the Vehicle.
                        </li>
                        <li>
                          not allow the Vehicle to be driven by anyone not holding a valid driver’s licence for that category of motor vehicle or where the driver’s licence has been endorsed for any reason or driving while under the influence of intoxicating liquor or narcotic substances.
                        </li>
                        <li>
                          not permit any unauthorised person to drive the Vehicle during the term of this Agreement.
                        </li>
                        <li>
                          not convey any materials or articles in the Vehicle that may cause damage to its upholstery or to any other part of the Vehicle.
                        </li>
                        <li>
                          not use or permit the Vehicle to be used for any commercial enterprise or activities.
                        </li>
                        <li>
                          not drive or allow the Vehicle to be driven recklessly and/or negligently or in contravention of any road or traffics regulations or laws.
                        </li>
                        <li>
                          immediately inform us if the meter is faulty and repair or replace the meter without delay and you may not unseal or tamper with the meter in any way.
                        </li>
                        <li>
                          if the Vehicle is kept on premises that you do not own, immediately notify us in writing of the name and address of the owner of the premises and you must also notify the landlord in writing that we own the Vehicle.
                        </li>
                        <li>
                          not alter the Vehicle and you must return the Vehicle to us in its original condition.
                        </li>
                        <li>
                          not remove the Vehicle from the Republic of South Africa without our prior written consent, which, if given, may be subject to certain conditions and you hereby indemnify us against any direct or indirect loss or liability that we may incur as a result of your removal of the Vehicle without our prior consent.
                        </li>
                        <li>
                          not sell, transfer, let go of or give anyone else rights in the Vehicle, nor allow them to become subject to any legal burden and if the Vehicle becomes subject to legal burden, you will immediately procure its release from the same.
                        </li>
                      </ol>
                    </li>
                  </ol>
                </li>
                <li><span>VEHICLE MAINTENANCE AND SERVICING</span>
                  <ol>
                    <li>
                      The remainder of the manufacturer’s maintenance or service plan as indicated in Part A to this Agreement will be assigned to you. All items not included in the maintenance or service plan will be for your own account.
                    </li>
                    <li>
                      Where the maintenance or service plan on the Vehicle has lapsed, you must service and maintain the Vehicle at your own cost, strictly in accordance with the manufacturer’s recommendations and only at enfranchised dealers or the manufacturer.
                    </li>
                    <li>You must:
                      <ol>
                        <li>
                          exercise the same degree of care necessary to maintain the Vehicle in a good and roadworthy condition as would ordinarily be exercised if the Vehicle belonged to you;
                        </li>
                        <li>
                          abide by the manufacturer’s specifications in regard to the Vehicle and not change or alter any aspect of the Vehicle without the Bank’s prior written consent to do so;
                        </li>
                      </ol>
                    </li>
                    <li>
                      All parts or accessories that are replaced on or added to the Vehicle will become our property without compensation to you.
                    </li>
                    <li>
                      The Vehicle will be installed with a tracking device so that the Bank can monitor the location and kilometres and other usage of the Vehicle and monitor whether the Vehicle is maintained in accordance with the terms and conditions of this Agreement.
                    </li>
                    <li>
                      Where you fail to submit the Vehicle for its recommended service timeously and the maintenance or service plan is terminated, you will be responsible for and must pay to the Bank, on demand, any costs associated with reinstatement of the maintenance or service plan and any costs arising from the Bank’s having to service the Vehicle.
                    </li>
                    <li>
                      The Bank will conduct an annual inspection of the Vehicle on or about each anniversary date of this Agreement. You must download a photograph of the Vehicle’s license disc, odometer reading and left and right-side front and rear bumpers of the Vehicle to the electronic link that the Bank will send to you at your chosen e-mail address.
                    </li>
                    <li>
                      Notwithstanding clause 7.7 above, we may conduct a physical inspection of the Vehicle at any reasonable time, wherever it is kept.
                    </li>
                  </ol>
                </li>
                <li><span>INSURANCE</span>
                  <ol>
                    <li>
                      The Vehicle is insured with Standard Bank Insurance Limited and the Bank’s interest must be noted on the insurance policy.  The monthly insurance premium due is included in the rental payable by you.
                    </li>
                    <li>
                      You confirm that a copy of the insurance policy was provided to you on signature of this Agreement.
                    </li><li>
                      You must comply with all the terms and conditions of the insurance policy.
                    </li>
                    <li>Subject to your rights under the CPA, if the Vehicle is:
                      <ol>
                        <li>
                          damaged, destroyed or lost, you must immediately notify us in writing and if required by us you must do everything necessary to ensure payment to us of the proceeds under the insurance policy; and/or
                        </li>
                        <li>
                          damaged and if required by us, you must restore the Vehicle at your own cost according to the manufacturer’s specifications and continue to meet all your obligations on or before their due date; and/or
                        </li>
                        <li>
                          damaged beyond economic repair, you must continue to meet your obligations to us under this Agreement or we will be entitled to cancel this Agreement by following the provisions of clause 9.4 of this Agreement; and/or
                        </li>
                        <li>
                          lost or destroyed and, where applicable, not recovered by us for any reason whatsoever, the value of the Vehicle will be deemed to be nil and we will be entitled to claim from you all outstanding amounts under this Agreement.
                        </li>
                      </ol>
                    </li>
                    <li>
                      We will not be required to provide you with a substitute Vehicle or to credit your Account for lack of use of the Vehicle whether under the circumstances referred to in clause 8.4 or on any other occasion when the Vehicle is not available for your use.
                    </li>
                    <li>
                      Should any claim be made or any proceedings be instituted against us, you or any other person as a direct or indirect consequence of the use, possession, maintenance or operation of the Vehicle or as a result of an insurable event, you will notify us of such proceeding or event immediately.
                    </li>
                    <li>
                      You will cooperate fully with us and render all assistance in the investigation and defence of any claim or proceedings arising from a situation referred to in clause 8.6.
                    </li>
                  </ol>
                </li>
                <li><span>DEFAULT</span>
                  <ol>
                    <li>Default in terms of this Agreement will occur:
                      <ol>
                        <li>
                          if you fail to keep the Vehicle licensed and registered with the relevant authorities;
                        </li>
                        <li>
                          if you fail to pay any amount payable to us under this Agreement on the due date;
                        </li>
                        <li>
                          if there is a material deterioration in your financial position. (For purposes of this clause, “material deterioration” means material deterioration in our reasonable opinion.)
                        </li>
                        <li>
                          if you fail to comply with any legislation or regulations applicable to this Agreement and your activities including but not limited to any environmental laws or responsibilities and, where applicable, any company legislation;
                        </li>
                        <li>
                          if you breach any of the terms and conditions of this Agreement or any agreement in terms of which you provided Collateral to us, and you fail to remedy the breach within the timeframe provided for in the written notice to do so;
                        </li>
                        <li>
                          if any representation or warranty made in connection with this Agreement or any other document supplied by you is materially incorrect or false;
                        </li>
                        <li>should you or any Collateral Provider (being a Natural Person):
                          <ol>
                            <li>
                              publish notice of the voluntary surrender of your/their estate or die;
                            </li>
                            <li>
                              be placed under administration or commit an act of insolvency as defined in the Insolvency Act 24 of 1936;
                            </li>
                            <li>
                              have any application or other proceedings brought against or in respect of you/them in terms of which you/they are sought to be sequestrated or placed under curatorship, whether provisionally or finally, whether voluntarily or under compulsion;
                            </li>
                          </ol>
                        </li>
                        <li>
                          if you generally do or omit to do anything that may affect our rights or Collateral in terms of this Agreement; or
                        </li>
                        <li>
                          if judgment of a competent court against you or any Collateral Provider for the attachment of assets or for payment of any amount remains unsatisfied for more than 7 (seven) days after the date on which it is issued.
                        </li>
                      </ol>
                    </li>
                    <li>If any of the above events happen or if the Vehicle is lost, destroyed or damaged beyond economic repair, we may, without prejudice to our other rights, after due demand:
                      <ol>
                        <li>
                          claim from you immediate payment of all amounts payable under this Agreement, whether due for payment or not, and if you do not make immediate payment, we may claim in terms of clause 9.2.2, whether or not we choose to claim payment in terms of this subclause; or
                        </li>
                        <li>cancel this Agreement, take the Vehicle back and recover from you all arrear rentals. We may also recover, as pre-estimated liquidated damages,
                          <ol>
                            <li>
                              the sum of all amounts payable over the unexpired period of this Agreement as well as all the quoted costs to repair any damage to the Vehicle, fair wear and tear excepted, and any excess usage charges in respect of the Vehicle;
                            </li>
                            <li>
                              less the value of the Vehicle as at the date on which we recover the Vehicle or the proceeds of any insurance policy paid to us in respect of the Vehicle, net of all costs of transportation, storage, valuation and improvements to the Vehicle. If we are unable to recover the Vehicle, the value will be deemed to be nil.
                            </li>
                          </ol>
                        </li>
                      </ol>
                    </li>
                    <li>
                      For all purposes of clause 9.2, “due demand” means written notice to you calling upon you to remedy an act of default or breach within a specified time period.
                    </li>
                    <li>
                      We will be allowed to choose a sworn appraiser to value the Vehicle and you will accept this valuation. If we recover the Vehicle, we may dispose of it in any way.
                    </li>
                    <li>
                      You will pay to us arrear interest at 3% (three per cent) above the Prime Rate on all overdue amounts. Interest will accrue from the date on which we acquire the right to claim from you until the date on which we receive payment from you.
                    </li>
                    <li>
                      If you fail to fulfil any obligation under this Agreement, we may (but do not have to), without prejudice to any of our other rights, fulfil the obligation on your behalf and you must reimburse us for our cost of doing so on demand.
                    </li>
                    <li>
                      If we have to pay you any amount in connection with this Agreement, that amount will not be due by us until you have paid all amounts you owe to us. We may set off any amount that we owe you against any amount you owe us under this Agreement.
                    </li>
                  </ol>
                </li>
                <li><span>EARLY TERMINATION</span><br >
                  You may terminate this Agreement before the expiry date with our prior written consent, which we will not unreasonably refuse. If we consent to the early termination, you must immediately return the Vehicle at your own cost and pay to us all arrear rentals, arrear interest, the excess usage charges in terms of clause 9.2 if applicable, the quoted costs of repair of the Vehicle (fair wear and tear excepted) and an early termination fee quoted by us on the basis of, among other things, clause 9.2.2.1.
                </li>
                <li><span>YOUR OBLIGATIONS ON TERMINATION OF THIS AGREEMENT</span>
                  <ol>
                    <li>
                      On termination of this Agreement for any reason you must at your own cost return the Vehicle to us together with the keys, accessories, instruction books (if any), the service and warranty books, and the spare wheel.
                    </li>
                    <li>On return of the Vehicle:
                      <ol>
                        <li>
                          we or our Agent will inspect the Vehicle according to the Fair Wear and Tear Guide attached hereto as Annexure B to determine any damages to the Vehicle and will complete an appraisal report. You or your agent may attend the inspection.
                        </li>
                        <li>
                          you or your agent must sign the appraisal report and endorse on the report in which respects you disagree with it. We will give you a copy of the report.
                        </li>
                      </ol>
                    </li>
                    <li>The report will be deemed to be correct:
                      <ol>
                        <li>
                          if you or your agent do not attend the inspection, and/or
                        </li>
                        <li>
                          with the exception of your or your agent’s endorsements on the report and latent defects.
                        </li>
                      </ol>
                    </li>
                    <li>
                      If the Bank is required to make any repairs to the Vehicle, fair wear and tear excepted, or replace any accessories, keys or the spare wheel, these costs will be for your account.
                    </li>
                    <li>
                      Any additions and/or alterations made to the Vehicle while it is in your possession will become the property of the Bank
                    </li>
                    <li>
                      You warrant that the Vehicle returned to us will be in the condition specified in the Fair Wear and Tear Guide.
                    </li>
                    <li>
                      If you dispute the condition of the Vehicle, the Vehicle will be examined by the manufacturer or, at our option, by an independent appraiser chosen by us (or his nominee) (“the expert”). The expert will act as an expert and not as an arbitrator and his decision will be final and binding on the Parties. The costs of the expert will be shared equally between the Parties.
                    </li>
                    <li>
                      If you do not return the Vehicle within 24 (twenty four) hours of the termination of this Agreement we may, without prejudice to our other rights, claim payment of an amount equal to the basic rental of the Vehicle for each month or part thereof that you have possession of the Vehicle after termination date.
                    </li>
                  </ol>
                </li>
                <li><span>EXCESS USAGE</span>
                  <ol>
                    <li>
                      You agree that you have decided on the maximum monthly kilometres and estimated monthly usage of the Vehicle as stated in Part A of this Agreement,
                    </li>
                    <li>
                      You undertake that the average monthly usage of the Vehicle during any period under review will not exceed the average estimated monthly usage during that period by more than 10% (ten per cent). The Bank will be entitled to charge you an excess-kilometre fee as indicated in Part A to this Agreement for each kilometre in excess of the maximum monthly kilometres allowed. You will be notified in writing within 10 (ten) days from the end of each month of the excess-kilometre fee due and payable by you for the previous month. The Bank will issue a tax invoice to you for the payment of the excess-kilometre fees.
                    </li>
                    <li>
                      Notwithstanding anything to the contrary contained in this Agreement, if you exceed the agreed estimated maximum monthly kilometres by more than 10% (ten percent) in any three successive month period of the term of the Agreement, then the Bank has the right to restructure the Agreement, which may result in an increase of the monthly rental in Part A and/or an amendment of the rental period. The Bank may also exercise the right to cancel this Agreement in accordance with clause 9 above should the Bank at its sole discretion determine it necessary to do so.
                    </li>
                    <li>
                      The tracking device installed in the Vehicle must be used to determine the distance travelled by the Vehicle during the entire rental period. The information provided by the tracking device will be conclusive proof of the distance travelled and will be binding on both parties, except in the event of manifest error or tampering with the tracking device, in which case the Vehicle odometer reading will be relied on.
                    </li>
                    <li>
                      The tracking device will also monitor the speeds you travelled during the entire rental period. The information furnished by the tracking device will be conclusive proof of the speeds travelled and will be binding on the parties, except in the event of manifest error.
                    </li>
                  </ol>
                </li>
                <li><span id="tracking_device">TRACKING DEVICE</span>
                  <ol>
                    <li>
                      A tracking device must be installed in the Vehicle before delivery.
                    </li>
                    <li>
                      You will under no circumstances whatsoever be entitled to remove the tracking device or tamper with it. The removal of or any tampering with the tracking device will be considered a material breach of this Agreement and the Bank will be able to exercise its rights in terms of clause 9 above.
                    </li>
                    <li>
                      Should the tracking device be faulty or damaged, the Bank will have it repaired as soon as reasonably practicable at its own cost. You will be obliged to take the Vehicle to a designated repair centre on receiving notice from the Bank to do so. The distance travelled during the period of such failure will be determined by the odometer of the Vehicle.
                    </li>
                    <li>
                      You irrevocably consent to the tracking of the Vehicle by the Bank.
                    </li>
                  </ol>
                </li>
                <li><span>ADDRESS FOR NOTICE AND SERVICE OF LEGAL DOCUMENTS</span>
                  <ol>
                    <li>
                      You and we choose our respective street addresses as set out above (“domicilium”) for all purposes of this Agreement. Each party may change its domicilium to another street address in the Republic of South Africa on written notice to the other.
                    </li>
                    <li>Unless otherwise stated, notices must be given in writing, and notice will be deemed to have been received:
                      <ol>
                        <li>
                          seven Business Days after being posted, if it was sent by registered post, or
                        </li>
                        <li>
                          on the next Business Day if it was delivered by hand or
                        </li>
                        <li>
                          on transmission of a facsimile or other recognised electronic transmission to the party’s then fax number or electronic mail address.
                        </li>
                      </ol>
                    </li>
                    <li>
                      Where the Post Office does not make street deliveries at your domicilium, you authorise us to send notices under this Agreement to your box number for postal deliveries from time to time.
                    </li>
                  </ol>
                </li>
                <li><span id="registration_licence">REGISTRATION, LICENCE FEES AND FINES</span>
                  <ol>
                    <li>
                      The Vehicle will be registered with us being the Title Holder and you being the Owner.
                    </li>
                    <li>
                      You can only accept delivery of the Vehicle after we have ensured that the Vehicle is registered and licensed.
                    </li>
                    <li>
                      You will be liable for the annual licence renewals and, if applicable, any re-registrations.
                    </li>
                    <li>
                      It is your duty to submit the Vehicle to an appropriate authority at your own cost to obtain a certificate of fitness as required from time to time in terms of prevailing road transportation legislation. You will be liable for all costs incurred in the issuing of any such certificate.
                    </li>
                    <li>
                      It is your duty to display all tokens, certificates and licences required from time to time in terms of the prevailing road transportation and/or other legislation.
                    </li>
                    <li>
                      You will be liable for all fines, penalties and toll fees arising directly or indirectly out of or in connection with the use, possession, driving or parking of the Vehicle.
                    </li>
                  </ol>
                </li>
                <li><span>WARRANTIES, REPRESENTATIONS AND UNDERTAKINGS</span>
                  <ol>
                    <li>You warrant and represent to us on the date on which you sign this Agreement and every day thereafter for the duration of this Agreement that:
                      <ol>
                        <li>
                          you have the full capacity to effect and carry out your obligations in terms of this Agreement;
                        </li>
                        <li>
                          the terms of this Agreement do not conflict with and do not constitute a breach of the terms of any other agreement or undertaking or act that is binding on you;
                        </li>
                        <li>
                          you and any person who provides Collateral have advised us if there are any requirements in your/their Constitutive Documents that have the effect of amending any alterable provisions of the Companies Act, particularly in respect of your borrowing powers and, if applicable, your/their power to provide Collateral, and that you/they have taken all necessary steps, fulfilled all formalities and obtained all approvals required by your/their Constitutive Documents as well as the Companies Act;
                        </li>
                        <li>
                          all the information that you provided to us in connection with the conclusion of this Agreement is in all aspects true, complete, current and accurate, and that you are not aware of any material facts or circumstances not disclosed to us that, if disclosed, would adversely affect our decision to conclude this Agreement;
                        </li>
                        <li>
                          you are not in default in respect of any of your material obligations in connection with this Agreement and that no default as specified in clause 9 above has occurred or is occurring;
                        </li>
                        <li>you will comply at all times with legislation and other laws applicable to this Agreement and to your activities including but not limited to, where applicable:
                          <ol>
                            <li>
                              ensuring that the Vehicle is properly licensed and registered with the relevant authorities at all times for the duration of this Agreement;
                            </li>
                            <li>
                              ensuring that any person who operates or uses the Vehicle is in possession of a valid licence or registration certificate from the relevant authorities at all times for the duration of this Agreement;
                            </li>
                            <li>
                              all environmental laws and responsibilities; and
                            </li>
                            <li>
                              confirming that you have complied and will comply with all exchange control regulations, rulings and requirements applicable to this Agreement from time to time.
                            </li>
                          </ol>
                        </li>
                      </ol>
                    </li>
                    <li>
                      You must tell us immediately if you are placed under an administration order, become insolvent, or have any form of legal disability. If you apply for insolvency, any amount outstanding under this Agreement will immediately become due, owing and payable to us.
                    </li>
                  </ol>
                </li>
                <li><span>DATA PROTECTION</span>
                  <ol>
                    <li>
                      You consent to us collecting your Personal Information from you and where lawful and reasonable, from public sources for credit, fraud and compliance purposes, as well as the purposes set out below.
                    </li>
                    <li>
                      If you give us Personal Information about or on behalf of another person (including, but not limited to, account signatories, shareholders, principal executive officers, trustees and beneficiaries), you confirm that you are authorised to: (a) give us the Personal Information; (b) consent on their behalf to the Processing of their Personal Information, specifically any cross-border transfer of Personal Information into and outside the country where the products or services are provided; and (c) receive any privacy notices on their behalf.
                    </li>
                    <li>You consent to us Processing your Personal Information:
                      <ol>
                        <li>
                          to provide products and services to you in terms of this agreement and any other products and services for which you may apply;
                        </li>
                        <li>
                          to carry out statistical and other analyses to identify potential markets and trends, evaluate and improve our business (this includes improving existing and developing new products and services);
                        </li>
                        <li>
                          in countries outside the country where the products or services are provided. These countries may not have the same data protection laws as the country where the products or services are provided. Where we can, we will ask the receiving party to agree to our privacy policies;
                        </li>
                        <li>
                          by sharing your Personal Information with our third-party service providers and insurers, locally and outside the country where the products or services are provided. We ask people who provide services to us to agree to our privacy policies if they need access to any Personal Information to carry out their services; and
                        </li>
                        <li>
                          within the Standard Bank Group.
                        </li>
                      </ol>
                    </li>
                    <li>
                      You will find our Processing practices in the Standard Bank Group’s and our privacy statements. These statements are available on the Standard Bank Group’s websites or on request.
                    </li>
                    <li>
                      If you are unsure about your tax or legal position because your Personal Information is Processed in countries other than where you live, you should get independent advice.
                    </li>
                  </ol>
                </li>
                <li><span>SANCTIONS</span>
                  <ol>
                    <li>You must not:
                      <ol>
                        <li>
                          act in a way that benefits a Sanctioned Entity;
                        </li>
                        <li>
                          be involved in any Prohibited Activities; or
                        </li>
                        <li>
                          use any product or service provided by the Standard Bank Group for any Prohibited Activities.
                        </li>
                      </ol>
                    </li>
                    <li>You warrant (promise) that you are not:
                      <ol>
                        <li>
                          a Sanctioned Entity; or
                        </li>
                        <li>
                          being investigated for any activities relating to Sanctions.
                        </li>
                      </ol>
                    </li>
                    <li>
                      You must let us know immediately in writing if you are being investigated for any activities relating to Sanctions.
                    </li>
                    <li>You indemnify the Standard Bank Group (excluding Liberty Holdings Limited and its subsidiaries) against (you are responsible for) any fines, penalties, losses, damages, costs, actions, proceedings, claims or demands (Losses) which the Standard Bank Group (excluding Liberty Holdings Limited and its subsidiaries) may suffer because:
                      <ol>
                        <li>
                          any funds are seized or withheld by any Sanctioning Body or any other third party (including by us); or
                        </li>
                        <li>
                          you breach this clause 18.
                        </li>
                      </ol>
                    </li>
                    <li>If we know or suspect that you are in breach of this clause 18 or you are about to become subject to Sanctions, we can immediately, in our sole discretion:
                      <ol>
                        <li>
                          close, restrict activity or suspend access to the Accounts and any other product or service we provide you; and/or
                        </li>
                        <li>
                          cancel this Agreement and/or any other relationship which we have with you.
                        </li>
                      </ol>
                    </li>
                    <li>
                      We are not liable (responsible) to you for any Losses you suffer if we cancel these Terms or any other relationship which we have with you.
                    </li>
                  </ol>
                </li>
                <li><span>INFORMATION SHARING</span>
                  <ol>
                    <li>By entering into this Agreement, you acknowledge and agree that we may provide the following information to any registered credit bureaux:
                      <ol>
                        <li>
                          details provided by you in your application for credit and in this Agreement; and/or
                        </li>
                        <li>
                          details of the conduct on your Account; and/or
                        </li>
                        <li>
                          details of any adverse information. In respect of such adverse information, we will give you at least 20 (twenty) Business Days’ notice of our intention to provide the credit bureaux with this information; and/or
                        </li>
                        <li>
                          details of the transfer of our rights as a credit provider under this Agreement to another person; and/or
                        </li>
                        <li>
                          any other details as may be required by any applicable law.
                        </li>
                      </ol>
                    </li>
                    <li>
                      Based on their records, the credit bureaux may provide a credit profile and/or a credit score on your creditworthiness to other credit providers.
                    </li>
                    <li>
                      You have the right to contact the credit bureaux to have your credit record with it disclosed and to request the correction of inaccurate information. The name(s) and contact details of the credit bureaux will be made available to you on request and shall also be made available on our website.
                    </li>
                    <li>
                      We may provide details to the South African Fraud Prevention Services (“SAFPS”) of any conduct on your Account that gives us reasonable cause to suspect that the Account is being used for improper purposes. The SAFPS may in turn make this information available to other members of the SAFPS if they carry out credit or other checks on your name.
                    </li>
                    <li>
                      You agree that we may provide any Collateral Provider with a copy of this Agreement, together with any amendments thereto and/or details of the conduct of your Account at their request. In the event of any proposed or actual change referred to in this clause 19.5 we reserve the right to cancel this Agreement.
                    </li>
                  </ol>
                </li>
                <li><span>CESSION BY THE BANK</span>
                  <ol>
                    <li>
                      To the maximum extent permitted by law, we may, without your consent or notice to you, cede all or part of our rights and/or delegate all or any part of our obligations under this Agreement, either absolutely or as collateral to any person, even though that cession and/or delegation may result in a splitting of claims against you.
                    </li>
                    <li>If this Agreement is sold or ceded, you acknowledge that the servicing and/or administration of this Agreement may, to the maximum extent permitted by law:
                      <ol>
                        <li>
                          be performed by us on behalf of the purchaser/cessionary; or
                        </li>
                        <li>
                          be contractually managed by a third party on behalf of the purchaser/ cessionary; or
                        </li>
                        <li>
                          be performed by the purchaser/cessionary itself.
                        </li>
                        <li>
                          These services and administrations can include the giving of notices, the realisation of any Collateral and the recovery of amounts due under this Agreement.
                        </li>
                      </ol>
                    </li>
                    <li>If this Agreement, or any part thereof, is sold, ceded and/or delegated to any person (“the purchaser/cessionary”), you acknowledge that:
                      <ol>
                        <li>
                          we may elect not to cede or sell our rights under any Collateral given directly or indirectly in respect of your obligations under this Agreement (“relevant Collateral”) to the purchaser/cessionary;
                        </li>
                        <li>
                          notwithstanding the sale, cession and/or delegation of this Agreement, or any part thereof, to the purchaser/cessionary, the relevant Collateral, or any part thereof as the case may be, will continue to cover your indebtedness to us, our successors in title or assigns under the relevant Collateral, on the terms set out in the relevant Collateral, or any part thereof as the case may be.
                        </li>
                      </ol>
                    </li>
                    <li>
                      Unless the context indicates otherwise, any reference to us in this Agreement will be deemed to include our cessionary, successor in title or agent.
                    </li>
                  </ol>
                </li>
                <li><span>STATEMENTS</span>
                  <ol>
                    <li>
                      We will provide you with a periodic Statement of your Account.
                    </li>
                    <li>
                      You may dispute (i.e. query) all or part of the Statement delivered to you by sending us written notice of your dispute.
                    </li>
                    <li>
                      You should contact our Customer Contact Centre if you do not receive a Statement or if you require any additional Statements. If you do not receive a Statement, this will not entitle you to refuse or fail to pay any amount that is due to us.
                    </li>
                    <li>
                      Subject to the provisions of clause 21.1 of this Agreement, a transfer from or a credit to your Account will be processed on the date on which the transaction is effected.
                    </li>
                    <li>
                      We may subsequently adjust debits or credits to your Account so as to accurately reflect both your and our legal obligations.
                    </li>
                  </ol>
                </li>
                <li><span>TAX INVOICE</span><br >
                  The Parties agree that this Agreement complies with the requirements under section 20(7) or 21(5) of the VAT Act.
                </li>
                <li><span>GENERAL</span>
                  <ol>
                    <li>
                      This is the whole Agreement between the Parties and no variation or waiver of any right under this Agreement will be of any force or effect unless it is agreed in writing.
                    </li>
                    <li>
                      No indulgence we may give you will affect any of our rights against you.
                    </li>
                    <li>
                      Should any of the clauses (or any portion of the clauses) in this Agreement be found to be invalid, illegal or unenforceable, this will not affect the remaining clauses in this Agreement, which will continue with full force and effect.
                    </li>
                    <li>
                      South African law will govern this Agreement. You consent to the Magistrate’s Court having jurisdiction in respect of any legal steps taken against you, even if the amount we claim exceeds the jurisdiction of the Magistrate’s Court. We may, however, approach a higher court if we wish to do so, in which case we may recover such costs as are awarded in the higher court.
                    </li>
                    <li>
                      A certificate signed by any of our managers (whose position as such need not be proved) as to any amounts owing by you in terms hereof will be prima facie proof (that is, sufficient proof unless the contrary is proved) for all purposes of your indebtedness to us. A certificate signed by our auditors (whose appointment need not be proved) as to the residual value of the vehicle and/or as to the increased costs under this Agreement from time to time will be conclusive proof of the contents thereof.
                    </li>
                    <li>
                      Any agreed changes to this Agreement will be made in writing, or if the changes are recorded telephonically, we will provide you with written confirmation of the change. We will deliver to you a document reflecting the agreed amendment of the Agreement no later than 20 (twenty) Business Days after the date of such amendment. Unless specifically stated and agreed in writing, no amendment to this Agreement will create a new agreement. The provisions of the Electronic Communications and Transactions Act 25 of 2002 do not apply to this clause.
                    </li>
                    <li>
                      Any version of this Agreement translated from this English version will be provided to you, on request, for information purposes only. While the translated version of the Agreement describes the rights and obligations contained in this Agreement, please note that this English version of the Agreement signed by you constitutes the legally binding Agreement between us. Therefore, any enforcement procedures in respect of the Agreement will be based on this English version.
                    </li>
                    <li>
                      You have the right to resolve any complaint by referring the matter to a dispute resolution agent, the consumer court or the ombud with jurisdiction and/or, in respect of any alleged contravention of the CPA, to file a complaint with the National Consumer Commission and/or make an application to the National Consumer Tribunal for an order resolving a dispute over information held by a credit bureau and/or an order compelling the delivery of a Statement and/or review of a Statement and/or permission to bring a complaint directly before the tribunal and/or an order allowing late filing.
                    </li>
                    <li>
                      The contact details of the Credit Bureau, the National Credit Regulator, the National Consumer Commission, the Tribunal and the Banking Ombud are available at your request and can also be found on our website.
                    </li>
                    <li>
                      To the extent that this Agreement, or the Goods or services that are the subject thereof, are governed by or subject to the CPA, no provision thereof is intended to contravene the applicable provisions of the CPA. All provisions of the Agreement will be deemed to be adequate to the extent required to ensure compliance with the applicable provisions of the CPA and this Agreement must be interpreted and applied accordingly.
                    </li>
                    <li>
                      You should contact us if you need further explanation of anything related to or referred to in, this Agreement.  You may contact us at our Customer Contact Centre, email us at employeerentalscheme@standardbank.co.za
                    </li>
                    <li>
                      The provisions of the National Credit Act No 24 of 2005 do not apply to the transaction recorded in this Agreement.
                    </li>
                    <li>
                      You must pay or reimburse us for VAT and any fiscal charges payable in connection with or arising from this Agreement and any costs, including attorney and own client costs, incurred by your breach of this Agreement.
                    </li>
                    <li>
                      Every term of this Agreement is separate and severable from the other. If a competent court finds any terms of this Agreement to be unenforceable, that term will be deleted and the remaining terms will not be affected.
                    </li>
                    <li>
                      If you cancel this Agreement you will pay us for the use of the Vehicle and any damage to the Vehicle while it is in your possession.
                    </li>
                    <li>
                      If anyone claims to be the owner of the Vehicle or to have greater rights to possess the Vehicle than you, you agree that any amounts already paid by you constitute payment for your use of the Vehicle up to date of repossession, whether or not you in fact used it.
                    </li>
                    <li>
                      A reference to any legislation includes any modified versions or derivatives or statutory re-enactments of that legislation that applies in South Africa.
                    </li>
                    <li>
                      This Agreement is in the official language chosen by you.
                    </li>
                  </ol>
                </li>
                <div class="Table">
                  <div class="Title">
                    <p><span id="employee_rental_scheme">EMPLOYEE RENTAL SCHEME MOTOR INSURANCE COVER <br ><br >The vehicles are comprehensively covered including roadside assistance and car-hire for the duration of the rental agreement.
                    The Insurance is underwritten by Standard Bank Insurance Limited. (VAF Fleet is the Insured not the renter of the vehicle)
                    </span></p>
                  </div>
                  <div class="Row">
                    <div class="Cell">
                      <p>Basis of cover:</p>
                    </div>
                    <div class="Cell">
                      <p>Motor Fleet Policy</p>
                      <p>Comprehensive cover – social and domestic use</p>
                    </div>
                  </div>
                  <div class="Row">
                    <div class="Cell1">
                      <p>Basis of indemnity:</p>
                    </div>
                    <div class="Cell">
                      <div class="Cell">
                        <p>Own damage:</p>
                        <p>Third-party liability:</p>
                        <p>Accident towing:</p>
                        <p>Roadside assistance:</p>
                        <p>SASRIA:</p>
                        <p>Windscreen:</p>
                        <p>Loss of keys:</p>
                        <p>Fire extinguishing charges:&nbsp;&nbsp;</p>
                        <p>Wreckage removal:</p>
                        <p>Car audio equipment:</p>
                      </div>
                      <div class="Cell">
                        <p>Retail value</p>
                        <p>R 2.5 million</p>
                        <p>R 3,000</p>
                        <p>Included</p>
                        <p>Included</p>
                        <p>Included</p>
                        <p>R10,000</p>
                        <p>R10,000</p>
                        <p>R25,000</p>
                        <p>R5,000</p>
                      </div>
                    </div>
                  </div>
                  <div class="Row">
                    <div class="Cell1">
                      <p>Car hire:</p>
                    </div>
                    <div class="Cell">
                      <p>Included – 30 days – nil time excess</p>
                    </div>
                  </div>
                  <div class="Row">
                    <div class="Cell1">
                      <p>Tracking system:</p>
                    </div>
                    <div class="Cell">
                      <p>Republic of South Africa, Namibia, Botswana, Lesotho, Swaziland, Zimbabwe or Malawi</p>
                    </div>
                  </div>
                  <div class="Row">
                    <div class="Cell1">
                      <p>Territorial scope:</p>
                    </div>
                    <div class="Cell">
                      <p>Included – 30 days – nil time excess</p>
                    </div>
                  </div>
                  <br >
                  <div class="Row">
                    <div class="Cell1">
                      <p><span>Excess/Deductible:</span></p>
                    </div>
                    <div class="Cell">
                      <p>&nbsp;</p>
                    </div>
                  </div>
                  <div class="Row">
                    <div class="Cell1">
                      <p>1st Claim:</p>
                    </div>
                    <div class="Cell">
                      <p>R3 500</p>
                    </div>
                  </div>
                  <div class="Row">
                    <div class="Cell1">
                      <p>2nd Claim:</p>
                    </div>
                    <div class="Cell">
                      <p>R5 500</p>
                    </div>
                  </div>
                  <div class="Row">
                    <div class="Cell1">
                      <p>3rd Claim:</p>
                    </div>
                    <div class="Cell">
                      <p>R11 000</p>
                    </div>
                  </div>
                  <div class="Row">
                    <div class="Cell1">
                      <p>Driver under 30 years old:</p>
                    </div>
                    <div class="Cell">
                      <p>additional R2 500</p>
                    </div>
                  </div>
                  <br >
                  <div class="Row">
                    <div class="Cell1">
                      <p>Single-vehicle event:</p>
                    </div>
                    <div class="Cell">
                      <p>R2 500</p>
                    </div>
                  </div>
                  <div class="Row">
                    <div class="Cell1">
                      <p>Windscreen Replacement:</p>
                    </div>
                    <div class="Cell">
                      <p>R1 000</p>
                    </div>
                  </div>
                  <div class="Row">
                    <div class="Cell1">
                      <p>Windscreen Repair:</p>
                    </div>
                    <div class="Cell">
                      <p>Nil</p>
                    </div>
                  </div>
                  <div class="Row">
                    <div class="Cell1">
                      <p>Locks &amp; Keys:</p>
                    </div>
                    <div class="Cell">
                      <p>additional R25</p>
                    </div>
                  </div>
                </div>
                <br >
                <li><span>INSURANCE</span>
                  <ol>
                    <li>
                      The Vehicle is insured with Standard Bank Insurance Limited and the Bank’s interest must be noted on the insurance policy.  The monthly insurance premium due is included in the rental payable by you.
                    </li>
                    <li>
                      You confirm that a copy of the insurance policy was provided to you on signature of this Agreement.
                    </li>
                    <li>
                      You must comply with all the terms and conditions of the insurance policy.
                    </li>
                    <li>Subject to your rights under the CPA, if the Vehicle is:
                      <ol>
                        <li>
                          damaged, destroyed or lost, you must immediately notify us in writing and if required by us you must do everything necessary to ensure payment to us of the proceeds under the insurance policy; and/or
                        </li>
                        <li>
                          damaged and if required by us, you must restore the Vehicle at your own cost according to the manufacturer’s specifications and continue to meet all your obligations on or before their due date; and/or
                        </li>
                        <li>
                          damaged beyond economic repair, you must continue to meet your obligations to us under this Agreement or we will be entitled to cancel this Agreement by following the provisions of clause 9.4 of this Agreement; and/or
                        </li>
                        <li>
                          lost or destroyed and, where applicable, not recovered by us for any reason whatsoever, the value of the Vehicle will be deemed to be nil and we will be entitled to claim from you all outstanding amounts under this Agreement.
                        </li>
                      </ol>
                    </li>
                    <li>
                      We will not be required to provide you with a substitute Vehicle or to credit your Account for lack of use of the Vehicle whether under the circumstances referred to in clause 8.4 or on any other occasion when the Vehicle is not available for your use.
                    </li>
                    <li>
                      Should any claim be made or any proceedings be instituted against us, you or any other person as a direct or indirect consequence of the use, possession, maintenance or operation of the Vehicle or as a result of an insurable event, you will notify us of such proceeding or event immediately.
                    </li>
                    <li>
                      You will cooperate fully with us and render all assistance in the investigation and defence of any claim or proceedings arising from a situation referred to in clause 8.6.
                    </li>
                  </ol>
                </li>
              </ol>
            </div>
            <div
              id="buy"
              class="tab-pane">
              <h3 class="headinng_sec_2">Type of Agreement - Credit Transaction Instalment Sale Agreement (Natural Persons)</h3>
              <h6>These are “standard terms and conditions” FOR NATURAL PERSONS (within NCA and within CPA) <br>
                Part B - Terms and conditions granted to a natural person</h6>
              <ol
                class="terms_ol"
                style="margin-top:40px">
                <li><span>Definitions</span>
                  <ol>
                    <li><span>Account</span>means the account in your name in our books in respect of this Agreement;</li>
                    <li><span>Agreed Term</span>means the term of the Agreement referred to under Part A or such other term agreed to by us in writing;</li>
                    <li><span>Agreement</span> means the pre-agreement statement and quotation/cost of credit section (Part A) of this agreement, attached to and read together with these terms and conditions (Part B) and all letters and notices relating to same;</li>
                    <li><span>Authority to  Release the Goods</span>means the authority signed by you confirming that you have inspected and received the Goods on our behalf from the Supplier;</li>
                    <li><span>Bank, Credit Provider, we, us, our</span> or <span>Standard Bank</span> means The Standard  Bank   of  South  Africa  Limited   (Registration  number 1962/000738/06)  acting through its Personal and Business Banking Division, a public company duly incorporated with limited liability according to the  company  laws of  the Republic  of South Africa and/or its successors in title or assigns;</li>
                    <li><span>Business Days</span> means any day/s other than a Saturday, Sunday or a statutory holiday in the Republic of South Africa;</li>
                    <li><span>Collateral</span>means any security or undertaking, including any guarantee, bond, indemnity or cession, provided to us or to the Guarantor to secure the repayment of your obligations to us under this Agreement and/or in terms of any written indemnity you have given to the Guarantor;</li>
                    <li><span>Collateral Provider</span> means each person and/or entity who is to provide Collateral to the Bank in respect of the due performance by you  of  your  payment  and   other  obligations   in  terms   of  this Agreement and <span>Collateral Providers</span> means any one of them as the context may indicate;</li>
                    <li><span>Collection Costs</span> means the amount that may be charged by us in enforcing your monetary obligations under this Agreement, but excludes any Default Administration Charges;</li>
                    <li><span>Companies Act</span> means Companies Act No 71 of 2008 and all regulations promulgated in terms of that act, as amended from time to time;</li>
                    <li><span>Constitutional Documents</span> means in the case of a company, the memorandum of association, articles of association, certificate to commence business, certificate of incorporation and/or the memorandum  of incorporation  and  registration certificate,  as  the case may be; or in the case of a close corporation, the founding statement; or in the case of a trust, the trust deed and letters of authority;</li>
                    <li><span>Control</span> shall have the meaning ascribed to it in section 2 of the Companies Act and <span>Controlled</span> or <span>Controlling</span> will have the same meaning. For the  purposes of this  definition, any reference  to a company, trust, or close corporation in the Companies Act, shall be deemed to refer to any analogous entity in any other jurisdiction;</li>
                    <li><span>CPA</span> means the Consumer Protection Act 68 of 2008 and all regulations promulgated in terms of that act;</li>
                    <li><span>Credit  Life  Insurance</span>  means  the  life  insurance  cover  that  is payable in the event of your death, disability, terminal illness, unemployment or other insurable risks (whichever applies as per the policy taken by you) that is likely to impair your ability to earn an income or meet your obligations under this Agreement;</li>
                    <li><span>Credit Record</span> means your payment profile (your credit history) including adverse information on a credit profile held by a credit bureaux;</li>
                    <li><span>Customer, you</span> or <span>your</span> means the person described as the customer in Part A;</li>
                    <li><span>“Customer’s  Group</span> means the Customer or any other entity which may, from time to time, form part of the Customer’s  group either as result of such entity Controlling any entity forming part of the Customer’s   group  alternatively such entity  is Controlled  by any entity forming part of the Customer’s  group (only applicable if the Customer forms part of a group);</li>
                    <li><span>Default Administration Charges</span> means charges which you must pay if you default in any payment obligation under this Agreement;</li>
                    <li><span>FAIS   Act</span>     means   the    Financial    Advisory  and  Intermediary Services Act 37 of 2002 and all regulations promulgated in terms of that act;</li>
                    <li><span>FICA</span> means the Financial Intelligence Centre Act 38 of 2001 and all regulations promulgated in terms of that act;</li>
                    <li><span>Goods</span> means the asset(s) described in Part A;</li>
                    <li><span>Group</span> means Standard Bank Group Limited, its subsidiaries and their subsidiaries;</li>
                    <li><span>Guarantor(s)</span> means a person(s) who undertake(s) to pay, in full or in part, the amount owing in terms of this Agreement in the event of a default by you under this Agreement;</li>
                    <li><span>Initiation Fee</span> means the fee (inclusive  of VAT) in respect of the cost of initiating this Agreement  payable by you;</li>
                    <li><span>Interest Rate Change Letter</span> means the letter sent to you stating the new interest rate applicable to the Agreement, following a Prime Rate change;</li>
                    <li><span>Juristic Person</span> does not include a private individual and for purposes of this definition, a trust with less than 3 (three) trustees, all of whom are natural persons;</li>
                    <li><span>Margin</span> means the agreed number of percentage points interest charged by us above or below the Prime Interest Rate;</li>
                    <li><span>Natural Person</span> means a private individual, and for purposes of this definition, a trust with less than 3 (three) trustees, all of whom are private individuals;</li>
                    <li><span>NCA</span> means the National Credit Act 34 of 2005 and all regulations promulgated in terms of this act;</li>
                    <li><span>Official Rate</span> means the official rate of interest as it is from time to time, used in the determination of taxable employment benefits, as prescribed  by  the  Income  Tax  Act  58  of  1962,  as  amended, replaced or re-enacted from time to time, being the reference rate in respect  of  Staff  (“Staff”  means  employees  of  the  Bank  or  the Group);</li>
                    <li><span>Parties</span> means you and us and <span>Party</span> means any one of us as the context may indicate;</li>
                    <li><span>Personal Information</span> means Information about an identifiable, natural person and where applicable, a juristic person, including, but not  limited  to  information  about:  race;  gender;  sex;  pregnancy; marital status; nationality; ethnic or social origin; colour; sexual orientation; age; physical or mental health; well-being; disability; religion; conscience; belief; culture; language; birth; education; medical; financial; criminal or employment history; any identifying number; symbol;  e-mail; postal or physical address; telephone number; location;  any online identifier; any other particular assignment to the person; biometric information; personal opinions; views  or preferences  of the  person  or  the views  or opinions  of another individual about the person; correspondence sent by the person that is implicitly or explicitly of a private or confidential nature or further correspondence that would reveal the contents of the original correspondence; and the name of the person if it appears with other personal information relating to the person or if the disclosure of the name itself would reveal information about the person;</li>
                    <li><span>Prime Interest Rate</span> means the publicly quoted variable base rate of interest per year ruling from time to time (as certified by any manager of the Bank, whose appointment it shall not be necessary to prove) at which the Bank lends, and such certification shall  be binding on the Parties in the absence of an obvious error;</li>
                    <li><span>Principal  Debt</span>  means  the  amount  deferred  in  terms  of  this Agreement;</li>
                    <li><span>Process</span> means any operation or activity, automated or not, concerning Personal Information, including: alteration, blocking, collation, collection, consultation, degradation, destruction, dissemination by means of transmission, distribution or making available in any other form, erasure, linking, merging, organisation, receipt, recording, retrieval, storage, updating, modification, or the use of information; <span>Processing</span> and <span>Processed</span> will have a similar meaning;</li>
                    <li><span>Prohibited Activities</span> means illegal or terrorist activities, money laundering or any activities which are subject to Sanctions or are not in compliance with applicable laws, regulations or practices;</li>
                    <li><span>Repayment</span> means the monthly instalment made, or to be made by you to us and <span>Repayment Due Date</span> means the due date for payment of all amounts due and payable as advised by us;</li>
                    <li><span>Repayment Authorisation</span> means the method by  which  you effect the repayment and includes a debit order;</li>
                    <li><span>Sanctions</span> means any restrictions set by a Sanctioning Body, including but not limited to diplomatic, travel, trade or financial sanctions or embargoes;</li>
                    <li><span>Sanctioning Body</span> means the United Nations Security Council <span>(UNSC)</span>, the Office of Foreign Assets Control of the Department of Treasury  of the United States of America <span>(OFAC)</span>, the European Union  <span>(EU)</span>,   Her  Majesty’s    Treasury  <span>(HMT)</span>,   the  Ministry  of Economy, Finance and Industry (France) <span>(MINEFI)</span> or any other sanctioning body the Bank recognise from time to time;</li>
                    <li><span>Sanctioned Entity</span> means:
                      <ol>
                        <li>
                          any natural or juristic person or any country;
                        </li>
                        <li>
                          in the case of a juristic person, any person who (i) Controls it; or (ii) it Controls;
                        </li>
                        <li>
                          in the case of any country, its ministries, departments, agencies or any other governmental organisations, listed on any Sanctions List or who is subject to any Sanctions;
                        </li>
                      </ol>
                    </li>
                    <li>
                      <span>Sanction List</span> means any list of Sanctioned Entities published by a Sanctioning Body, as updated from time to time;
                    </li>
                    <li>
                      <span>Service Fee</span> means the fee (inclusive of VAT) levied by us in connection with the routine administration costs of maintaining this Agreement;
                    </li>
                    <li>
                      <span>Settlement Date</span> means the date on which  all amounts due  and payable to us are paid;
                    </li>
                    <li>
                      <span>Settlement Value</span> means the full  amount owing to us under  this Agreement together with any unpaid interest and all other legally permissible costs, fees and charges as at the Settlement Date;
                    </li>
                    <li>
                      <span>Statement</span> means the document reflecting  the transactions made on your Account up to the date of the statement; the full amount due to us (including any legally permissible interest, costs, fees and charges that may be levied); the Repayment; and the Repayment Due Date;
                    </li>
                    <li>
                      <span>Supplier</span> means the party who supplies the Goods;
                    </li>
                    <li>
                      <span>Surety(ies)</span> means a person(s) who undertake(s) to pay, in full or in part, the amount owing in terms of this Agreement in the event of a default by you under this Agreement;
                    </li>
                    <li>
                      <span>Tribunal</span> means the National Consumer Tribunal established by section 26 of the NCA; and
                    </li>
                    <li>
                      <span>VAT</span> means value added tax charged in terms of the Value-Added Tax Act 89 of 1991 and all regulations promulgated in terms of this act.
                    </li>
                  </ol>
                </li>
                <li><span>Interpretation</span>
                  <ol>
                    <li>
                      Words defined, will (for consistency) begin with a capital letter.
                    </li>
                    <li>
                      Where any number of days is referred to in this Agreement, it will include  only  Business  Days  and  will  exclude  the  first  day  and include the last day.
                    </li>
                    <li>
                      If we are required to exercise our discretion in this Agreement, we will exercise such discretion in a reasonable manner.
                    </li>
                    <li>
                      The singular includes the plural and vice versa and any gender includes the other gender.
                    </li>
                    <li>
                      All headings are for convenience only and are not to be taken into account for the purposes of interpreting these terms and conditions.
                    </li>
                    <li>
                      If  any provision  in the  definitions section  is important  and gives rights to or imposes obligations on a Party, then effect will be given to that provision as if it were a provision in the body of this Agreement.
                    </li>
                    <li>
                      All legislation and subordinate legislation mentioned in this Agreement includes legislation or subordinate legislation at the date of signature of this Agreement and as changed or re-enacted from time to time.
                    </li>
                    <li>
                      Unless otherwise defined in this Agreement, words defined in the CPA and NCA will bear the same meanings in this Agreement.
                    </li>
                    <li>
                      Important clauses which may limit our responsibility or involve some risk for you, will be in bold.
                    </li>
                  </ol>
                </li>
                <li><span>Sale</span>
                  <ol>
                    <li>
                      We hereby sell the Goods to you and you purchase the Goods from us on the terms and conditions set out in this Agreement
                    </li>
                  </ol>
                </li>
                <li><span>Ownership of the Goods</span>
                  <ol>
                    <li>
                      <span>We will  be the  owner  of the  Goods for the  duration of this Agreement.</span>
                    </li>
                    <li>
                      <span>Ownership of the Goods will only pass to you once you have paid all amounts due and complied with of your obligations in terms of this Agreement</span>
                    </li>
                  </ol>
                </li>
                <li><span>Delivery and risk in the Goods</span>
                  <ol>
                    <li>
                      You have selected the Goods  from the Supplier and we have no knowledge of the purpose for which the Goods are to be used.
                    </li>
                    <li>
                      You must inspect the Goods on our behalf and if you are satisfied that the Goods are free of defects, you must sign the Authority to Release the Goods and give it to the Supplier. By doing this, you are instructing us to pay the Supplier for the Goods.
                    </li>
                    <li>
                      You will act as our agent when taking delivery of the Goods, so that we become the owner of the Goods. The Supplier will act as our agent for the purpose of delivering the Goods to you.
                    </li>
                    <li>
                      The Supplier and you will only act as our agent for the purposes set out in this Agreement.
                    </li>
                    <li>
                      Subject to your rights in the CPA, you agree that we have made no warranties or representations to you as to the state, condition or fitness of the Goods. We are not the manufacturer of the Goods, and we have not inspected the Goods and could therefore not reasonably have discovered any defects in the Goods.
                    </li>
                    <li>
                      The risk in the Goods will pass to you when you take delivery of the Goods or when you sign this Agreement, whichever is earlier.
                    </li>
                  </ol>
                </li>
                <li><span>Use and maintenance of the Goods</span>
                  <ol>
                    <li>You must:
                      <ol>
                        <li>
                          use the Goods with care and not do anything that may affect our rights in respect of the Goods, and you may not alter or modify the Goods without our consent;
                        </li>
                        <li>
                          comply with all laws relating to the possession, operation, licensing, registration, insurance and use of the Goods at your expense;
                        </li>
                        <li>
                          keep the Goods in your possession and under your control and not sell or part with possession of the Goods or allow the Goods to be removed from the Republic of South Africa without our prior written consent;
                        </li>
                        <li>
                          maintain and service the Goods in accordance with the manufacturer’s   specifications,  keep them  in good working  order and condition and ensure that they are only used by qualified and licensed drivers and operators;
                        </li>
                        <li>
                          ensure that the Goods are not permanently attached to, or form part of, any other property (movable or immovable) and provide us with a written  waiver from any person  from whom  we may require such waiver;
                        </li>
                        <li>
                          not use or allow the Goods to be used in an illegal  manner or for an illegal purpose or in a manner which may invalidate any insurance claim, nor use the Goods for the transportation of persons or goods for reward nor for any racing or dangerous activity;
                        </li>
                        <li>
                          not allow the Goods to become subject to any lien (a right by a third party to take possession of the Goods to satisfy any debt owing to them by you), hypothec (a right by a landlord to take possession of the Goods to satisfy any amounts owing to them by you) or attachment by a third party by way of an order of any court. In the event of a third party attaching the Goods as part of any lien, hypothec or any other encumbrance, you undertake to immediately obtain the release of the Goods from the third party.
                        </li>
                      </ol>
                    </li>
                    <li>
                      All replacement parts and accessories that you add to the Goods will  form  part  of  the  Goods  and  become  our  property  without payment of any compensation to you.
                    </li>
                    <li>
                      We, or our representatives, may at all reasonable times inspect the Goods on any premises where they are kept.
                    </li>
                    <li>
                      If you store the Goods on premises that you do not own, you must notify us in writing of the name and address of the landlord or the owner of the premises  and inform us  of any changes  in respect thereof during the currency of the Agreement. You must also inform the landlord or the owner of the premises that we own the Goods.
                    </li>
                  </ol>
                </li>
                <li><span>Loss of or damage to the Goods</span>
                  <ol>
                    <li>
                      If the Goods are damaged, destroyed or lost, you must immediately notify us in writing and do everything necessary to ensure payment to us of the proceeds under any insurance policy.
                    </li>
                    <li>
                      If so required by us, you shall repair and replace the Goods at your cost and continue to discharge all of your obligations under this Agreement on due date.
                    </li>
                    <li>If the Goods are lost, damaged or destroyed and we or the insurer of the Goods determine that the Goods are incapable of economic repair, we shall be entitled to terminate this Agreement and to claim in terms of 19.3.2 as if an event of default had occurred.</li>
                  </ol>
                </li>
                <li><span id="insurance_buy">Insurance</span>
                  <ol>
                    <li>You must ensure for the duration of this Agreement, that:
                      <ol>
                        <li>
                          the Goods are comprehensively insured  against all risks for which such Goods are normally insured, for no less than the total of your obligations to us  under this Agreement from time to time with an insurance company in terms of a policy acceptable to us; and
                        </li>
                        <li>
                          we are  provided with proof of such insurance on our request for same, from time to time. This proof of insurance must include that our interest as owner of the Goods has been noted.
                        </li>
                      </ol>
                    </li>
                    <li>
                      We  may  propose  an  insurance  company  to  you  and,  at  your request, arrange  insurance on your behalf.   If  we do so  we  will provide you with an insurance disclosure (that is, a disclosure of the cost of the insurance arranged by us and the amount of any fee, commission,  remuneration  or  benefit  which  we  may  receive  in respect of the insurance policy).</li>
                    <li>
                      You may arrange your own insurance cover with an insurer of your choice.  If we find the insurance unacceptable, we will notify you of the reason and we may then exercise our right to take up insurance with any insurer on your behalf and at your expense.
                    </li>
                    <li>
                      You confirm having been informed of your right to waive a policy of insurance proposed by us and to substitute it with a policy of your own choice.
                    </li>
                    <li>Irrespective of whether you exercise your right to substitute it with an insurance policy of your own choice or not, you must give us a written nomination and authority:
                      <ol>
                        <li>
                          to pay any premium on any insurance policy taken out by you or us in connection with this Agreement and debit your Account on a monthly/  yearly  basis  in  respect  of  such  premiums  which  will become payable by you and which will bear interest at the same rate as reflected in Part A.
                        </li>
                        <li>
                          nominating us as  the first loss payee under the  insurance policy (that is the recipient of the proceeds of a claim under that policy) and requiring the insurer to use the proceeds of any claim to settle your obligations to us under this Agreement on your behalf as a first charge against the policy to us;
                        </li>
                        <li>
                          authorising the insurer  concerned to make payment if an insured event occurs, to settle your obligations to us under this Agreement
                        </li>
                      </ol>
                    </li>
                    <li>
                      You consent to us, enquiring from your insurer at any time if your insurance policy is still in place and fully paid.
                    </li>
                    <li>
                      Where you fail to insure the Goods or provide proof that the Goods are insured as required in terms of clause 8.1 we will have the right, but not an obligation, to insure the Goods in our name and/or your name, at your expense, with an insurance company nominated by us. This may be as specified or deemed necessary by us at our sole discretion.
                    </li>
                    <li>
                      <span>If any insurer rejects an insurance claim for any reason or if the replacement cost of the Goods is more than the benefits under your insurance policy, you will have no recourse against us for any loss directly or indirectly incurred, unless the Bank or any person acting for or controlled by us acted with gross negligence.</span>
                    </li>
                    <li>
                      <span>You understand and acknowledge that the required insurance in terms of this Agreement does not constitute a formal insurance assessment. It is your responsibility to ensure that your insurance risk is adequately covered.</span>
                    </li>
                  </ol>
                </li>
                <li><span>Surrender of the Goods</span>
                  <ol>
                    <li>
                      You may terminate this Agreement by advising us that you wish to surrender the Goods to us and by delivering the Goods to us within 5  (five) Business Days of the date of the surrender notice with a request to us to sell the Goods.
                    </li>
                    <li>
                      We will appoint a valuator to value the Goods and advise you of the estimated value within 10 (ten) Business  Days of being placed in possession of the Goods.
                    </li>
                    <li>
                      Within 10 (ten) Business Days after receipt of the estimated value, you may (unless you are in default under this Agreement) unconditionally withdraw the notice of surrender of the Goods and you may resume possession of the Goods. If you are in default, you may only resume possession of the Goods if you have brought your Account up to date and no arrear amounts reflect on the Account.
                    </li>
                    <li>If you do not withdraw the notice, we will sell the Goods as soon as practicable for the best price reasonably obtainable and:
                      <ol>
                        <li>
                          credit or debit you with a payment or charge equivalent to the proceeds of the sale, less any expenses reasonably incurred by us in connection with the sale of the Goods;
                        </li>
                        <li>give you a written notice stating:
                          <ol>
                            <li>
                              the Settlement Value of the Agreement immediately before the sale;
                            </li>
                            <li>
                              the gross amount realized by the sale;
                            </li>
                            <li>
                              the  net  proceeds   of  the  sale after  deducting  permitted  default charges, if applicable, and reasonable costs incurred in selling the Goods; and
                            </li>
                            <li>
                              the amount credited or debited to the Account.
                            </li>
                          </ol>
                        </li>
                      </ol>
                    </li>
                    <li>
                      If the amount credited to your Account is less than the Settlement Value immediately before the sale or an amount is debited to your Account, we may demand payment from you of the remaining Settlement Value.
                    </li>
                    <li>If you:
                      <ol>
                        <li>
                          fail to pay the amount demanded by  us within 10 (ten) Business Days after receipt of the demand, we may commence legal proceedings for judgment enforcing this Agreement; or pay the amount  demanded  before  judgment  is obtained,  this Agreement will be terminated upon such payment, it being agreed that in either event  you shall  pay interest at the prevailing  interest rate on any outstanding amount demanded by us, from the date of demand until the date on which the outstanding amount is paid in full.
                        </li>
                      </ol>
                    </li>
                    <li>
                      If the proceeds of the sale of the Goods are more than sufficient to settle your full liability to us, leaving a credit in your favour, we will pay this to you provided that no other credit provider has a credit agreement in respect of the Goods with you.
                    </li>
                  </ol>
                </li>
                <li><span>Change  in  control  of  a  Company,  Close  Corporation, Partnership or Trust</span>
                  <ol>
                    <li>You must let us know in writing as soon as you become aware of any proposed or actual change in the direct or indirect ownership and/or control and/or management of:
                      <ol>
                        <li>
                          any trust  to  whom  the Goods  have  been sold in  terms  of this Agreement, and/or
                        </li>
                        <li>
                          any   Surety/Guarantor  for   your  obligations   to   us  under   this Agreement.
                        </li>
                      </ol>
                    </li>
                    <li>
                      Any change taking  place in  terms of clause 10.1 above will not affect any Collateral held by us nor will it release any Surety/Guarantor (if any) from a suretyship/guarantee.
                    </li>
                    <li>
                      In the event of any proposed or actual change referred to in this clause 10.1 we reserve the right to reconsider the terms and conditions of this Agreement.
                    </li>
                  </ol>
                </li>
                <li><span>Payments</span>
                  <ol>
                    <li>
                      If you are required to pay a deposit, you must pay the deposit as detailed in Part A of this Agreement.
                    </li>
                    <li>
                      If you have indicated that you will pay the Initiation Fee upfront into your Account and if you fail to pay such fee or any part thereof on the date on which you sign this Agreement, you agree that we may debit the unpaid Initiation Fee to the Account as part of the Principal Debt.
                    </li>
                    <li>
                      If you have indicated that you will not pay the Initiation Fee separately, this  fee  will  be added  to the  Principal Debt  and  be debited to your Account on your signature of this Agreement.
                    </li>
                    <li>
                      <span>You must pay all Repayments on or before the Repayment Due Date, by debit order instruction unless otherwise agreed to by us  in  writing,  without  any  deduction  or  demand,  for  the duration of this Agreement.</span>
                    </li>
                    <li>
                      <span>Each Repayment will be credited to your Account on date of receipt firstly to satisfy any due or unpaid interest, secondly to satisfy any due or unpaid costs, fees and charges, and thirdly to reduce the amount of the Principal Debt.</span>
                    </li>
                    <li>
                      We will notify you in the event of an increase in any legally permissible fee, charge, insurance premium or interest rate, in accordance with this Agreement, and you agree to increase your Repayment accordingly. In the event that you have  a debit order instruction in place,  you authorise us  to increase  the Repayment amount under your debit order instruction to ensure that your indebtedness to us will be repaid within the Agreed Term.
                    </li>
                    <li>
                      You have the right at any time to pay any amounts owed to us in advance without notice or penalty, irrespective of whether or not the amounts are due. You agree that any advance payments made by you under  this  Agreement  will  result  in  the  Repayments  being reduced  proportionately  so  as  to  not  change  the  term  of  this Agreement. If you want any advance Repayment to have the effect of  shortening  the  Agreed  Term  of  this  Agreement  rather  than reducing the Repayments, you must contact us to advise us of this.
                    </li>
                    <li>
                      If a third party takes possession of the Goods, you will remain responsible for the Repayments.
                    </li>
                    <li>
                      You will not be entitled to deduct any amount which we may owe to you, from any amount owing or which may become owing by you to us, arising from the terms of this Agreement.
                    </li>
                    <li>
                      If applicable, the monthly Service Fee will be debited to the Account.
                    </li>
                    <li>
                      We reserve the right, in our sole discretion, to accept Repayments made other than by way of debit order.
                    </li>
                  </ol>
                </li>
                <li><span>Statements</span>
                  <ol>
                    <li>
                      We will provide you with a periodic Statement of your Account.
                    </li>
                    <li>
                      You may dispute (i.e. query) all or part of the Statement delivered to you by sending us written notice of your query.
                    </li>
                    <li>
                      You should contact our Customer Contact Centre if you do not receive a Statement or if you require any additional Statements. Failure to receive a Statement will not entitle you to refuse or fail to pay any amount that is due to us.
                    </li>
                    <li>
                      Subject to  the  provisions  of  clause  23.1  of  this  Agreement,  a transfer from or a credit to your Account, will be processed on the date on which the transaction is effected.
                    </li>
                    <li>
                      We may subsequently adjust debits or credits to your Account so as to accurately reflect both your and our legal obligations.
                    </li>
                  </ol>
                </li>
                <li><span>Collateral</span>
                  <ol>
                    <li>
                      The Collateral, as detailed in the “Collateral” clause of Part A, is governed by the specific conditions applicable to each item.
                    </li>
                    <li>
                      We have the right to ask you to provide us with additional collateral, within  a  reasonable  period  of  time  after  our written request,  to secure repayment of the Agreement if the value of any Collateral held by us to secure your obligations in terms of this Agreement is no longer adequate or in our reasonable opinion, your conduct on the Account increases our risk under the Account or any other amount owing to us in terms of any other agreement.
                    </li>
                    <li>The Collateral may be realised in part or in full:
                      <ol>
                        <li>
                          if you are subject to the NCA – and you give written notice to us to terminate this Agreement and request that we sell (realise) any Collateral held by us for your obligations in terms of this Agreement. We may realise the Collateral in accordance with the procedures of the NCA and credit your Account with the proceeds from the realisation of the Collateral. If the amount credited to your Account exceeds  the  Settlement   Value  prior  to  the  realisation  of  the Collateral, and if there is another credit provider with a registered credit agreement in respect of the same Collateral, we are required by the NCA to pay the excess to the Tribunal; or
                        </li>
                        <li>
                          if you are not subject to the NCA – and you   give written notice to us to terminate this Agreement and request that we sell (realise) any Collateral which is held by us for your obligations in terms of this Agreement. We may realise the Collateral and credit your Account with the proceeds from the realisation of the Collateral; and/or
                        </li>
                        <li>
                          if you are in default in terms of this Agreement and we exercise our rights in terms of this Agreement in accordance with the Default clause in this Part B; and/or
                        </li>
                        <li>
                          where a court has issued an attachment order in our favour.
                        </li>
                      </ol>
                    </li>
                    <li>
                      If  the  proceeds  realised exceed  the  amount owing  by  you,  the excess will be paid to you, or to any Surety/Guarantor or mortgagor, whose supporting Collateral has been realised and has resulted in the receipt of excess funds.
                    </li>
                    <li>
                      Notwithstanding the Collateral held under this Agreement, we may share  in  any  other  collateral  held  by  the  Bank  for  any  other obligation  you may have  with  the Bank,  in the  event of default, breach or any other legal circumstance which may result in the Bank having to exercise its rights in terms of this Agreement.
                    </li>
                    <li>
                      Any further Collateral required by us in respect of this Agreement will not affect any other collateral that we may already hold or any rights that we may have in terms of this Agreement.
                    </li>
                  </ol>
                </li>
                <li><span>Costs, Fees and Charges</span>
                  <ol>
                    <li>
                      We may charge and recover fees in respect of this Agreement provided that the amount of any fee charged and recovered does not exceed the legal maximum permissible amount.
                    </li>
                    <li>
                      Unless stated otherwise, the costs, fees and charges referred to in this Costs, fees and charges clause are inclusive of VAT. All costs, fees and charges in respect of this Agreement will be debited to the Account.
                    </li>
                    <li>
                      Where there is a change in the frequency or time for payment of a fee or charge, we will give you written notice  of at least 5 (five) Business Days, setting out the particulars of the change.
                    </li>
                    <li>
                      If we charge a fee in respect of this Agreement which is less than the prescribed legal maximum, then we may at any time increase it by giving you written  notice of the increase no later than 5 (five) Business  Days   following  the  date  on  which  the  relevant  fee changed, setting out the amount of the new fee.
                    </li>
                    <li>
                      You must pay to us all applicable costs, fees and charges set out in this Costs, fees and charges  clause together with the Principal Debt and interest on the Principal Debt as set out in this Agreement.
                    </li>
                    <li>
                      The total of the amounts in Part A, in respect of the Initiation Fee, the Service Fee, the insurance and interest, where applicable, as well as Default Administration Charges and Collection Costs, which accrue when you are in default, may not exceed the unpaid balance of the Principal Debt at the time the default occurs.
                    </li>
                    <li>
                      <span>We may immediately amend the pricing structure applicable to the Principal Debt if there is a change in law or the issue of a directive with which we must comply and/or market conditions resulting in an increasing cost to us. If we do so we may advise you accordingly.</span>
                    </li>
                  </ol>
                </li>
                <li><span>Legal Costs and Charges</span>
                  <ol>
                    <li><span>Default Administration Charges</span>
                      <ol>
                        <li>
                          If you default in any payment obligation under this Agreement, we will charge you Default Administration Charges in respect of each letter sent to you. Default Administration Charges will be equal to the the amount payable in respect of a registered letter of demand in undefended actions (i.e. legal action which  is not defended by you) in terms of the Magistrates Court Act 32 of 1944, in addition to 17 any reasonable and necessary expenses incurred in delivering the 17.1 letter.
                        </li>
                        <li>
                          If you are in default of your Repayment obligations, you may at any 17.1.1 time before cancellation of the Agreement by us, pay us all amounts 17.1.2 that  are  overdue,  together  with  Default  Administration Charges, Collection Costs and/or reasonable legal costs incurred up to the 17.1.3 date of payment in terms of this clause, and we may reinstate this Agreement.
                        </li>
                      </ol>
                    </li>
                    <li><span>Collection Costs</span>
                      <ol>
                        <li>
                          We will charge you  Collection Costs incurred by us in collecting any amount due  and/or payable by  you in  terms of  this Agreement.
                        </li>
                        <li>
                          Collection  Costs  will  not  exceed  the  costs  incurred  by  us  in 17.2 collecting the debt, to the extent limited by law and in terms of the Supreme  Court Act  59 of 1959,  the Magistrates Court Act 32 of 1944, the Attorneys Act 53 of 1979, or the Debt Collectors Act 114 17.3 of 1998, whichever is applicable to the court approached by us to enforce this Agreement.
                        </li>
                      </ol>
                    </li>
                  </ol>
                </li>
                <li><span>Interest</span>
                  <ol>
                    <li>
                      The variable interest rate applicable to this Agreement, is linked to the   Prime   Interest   Rate   or   the   Official   Rate   (whichever is applicable), by a Margin related  to the Prime Rate or the Official Rate (whichever is applicable) that  is determined  by us  and has been disclosed in the "Variable interest rate" clause of Part A.
                    </li>
                    <li>
                      The interest rate applicable  to this Agreement  will change  if the Prime Rate or the Official Rate (whichever is applicable) fluctuates, provided that the new rate does not exceed any legal maximum permissible rate.  If we do amend the interest rate, we will advise you in writing  within  at least 30 (thirty) Business  Days after  the change becomes effective.
                    </li>
                    <li>
                      The  interest  rate  quoted  in  Part  A, to  which  these terms  and conditions are attached, applies to you if you are staff.  Should your employment with the Bank terminate for any reason, you will cease to qualify for the staff category interest rate and will fall within the non-staff category. In that event the interest rate will be amended to a rate that would be applicable to the non-staff category subject thereto that the rate does not exceed the maximum allowed in terms of the NCA.
                    </li>
                    <li>
                      Any amendment to the Prime Interest Rate will also result in an amendment to the  amount reflected under  the  Total cost of this Agreement clause of Part A.
                    </li>
                    <li>
                      You  may  apply  for  a  fixed  interest  rate  by  concluding  a  Fixed Interest Rate Option, however the granting of such a fixed interest rate will be at our discretion.
                    </li>
                    <li>
                      The interest payable by you is calculated on a daily basis on the outstanding  capital  balance  and  calculated  on a  365-day  year, irrespective of whether the  relevant year is a  leap year charged monthly in arrears and  is due and  payable on the Payment Due Date and debited to your Account.
                    </li>
                    <li>
                      We will be entitled to add any unpaid interest to the outstanding balance of the Principal Debt.
                    </li>
                    <li>
                      We may  charge  and  recover  interest on  and  in  respect  of any unpaid  interest,  insurance  premiums,  costs,  fees  and  charges referred  to  in  this  Agreement  from  you,  which  interest  will  be charged at a rate not exceeding the highest interest rate applicable in terms of this Agreement, provided that the amounts that accrue during the time you are in default will not in total exceed the unpaid balance of the Principal Debt at the time of default.
                    </li>
                    <li>
                      The default interest rate applicable to any amount by which you are in default and any amount which is not paid on its due date shall be the   maximum   interest   rate   prescribed   under   the   NCA   for transactions of the nature contemplated in this Agreement.
                    </li>
                    <li>
                      If  the  Prime  Interest  Rate,  the  Official  Rate  or  the  maximum permissible NCA interest rate (whichever is applicable) increases or decreases, the rate of interest payable by you may also decrease or increase by the same number of percentage points provided that this new rate does not exceed the legal maximum permissible rate. We shall in such event have the right to increase or reduce the Payments, as  the case may be, to such an amount as  the Bank determines, so as to ensure that your indebtedness to the Bank is repaid within the term of this Agreement.
                    </li>
                  </ol>
                </li>
                <li><span>Termination of this agreement by you</span>
                  <ol>
                    <li>You may terminate this Agreement and settle the amount owing to us at any time with or without advance notice to us. The amount required to settle this Agreement will be the total of:
                      <ol>
                        <li>
                          the unpaid balance of the Principal Debt as at the Settlement Date;
                        </li>
                        <li>
                          unpaid interest and all other costs, fees and  charges  payable by you to us up to and until the Settlement Date; and
                        </li>
                        <li>
                          if this Agreement is a large credit agreement as defined in the NCA, we may charge you an early termination charge in an amount equal to no more than the interest which would have been payable under this  Agreement  for  a  period  equal  to the  difference  between  3 (three) months and the period of notice of settlement, if any, given by you.
                        </li>
                      </ol>
                    </li>
                    <li>
                      If you would like a Settlement Value, we will provide it to you orally or, in writing, within 5 (five) Business Days of your request to do so.
                    </li>
                    <li>
                      If you did not enter into this Agreement at our registered business premises, and you elect to exercise your cooling off rights in terms of section 121 of the NCA, we will refund to you any payment made by you after you have delivered the notice of termination to us and after you have returned the Goods, less any reasonable costs, depreciation and rental as determined by us. You remain liable for any reasonable costs of having the Goods returned and restored to a saleable condition.
                    </li>
                  </ol>
                </li>
                <li><span>Warranties, Representations and Undertakings</span>
                  <ol>
                    <li>You warrant and represent to us on the date on which you sign this Agreement and for the duration of this Agreement that:
                      <ol>
                        <li>
                          Since applying to the Bank for this Agreement:
                        </li>
                        <li>
                          there has been no deterioration in your financial position;
                        </li>
                        <li>
                          you have not applied for or taken up any additional credit;
                        </li>
                        <li>
                          you are not under debt counselling or subject to debt review, nor have you applied for debt review, as at the date of signature of this Agreement by you;
                        </li>
                        <li>
                          you are not subject to any administration order referred to in section 74(1) of the Magistrate’s  Court Act or any business rescue order; and
                        </li>
                        <li>
                          you are not insolvent and have not committed any acts of insolvency in terms of the Insolvency Act 24 of 1936;
                        </li>
                        <li>
                          you have the full capacity to effect and carry out your obligations in terms of this Agreement and that the terms of this Agreement do not conflict with and do not constitute a breach of the terms of any other agreement or undertaking or act that is binding on you;
                        </li>
                        <li>
                          if applicable, you and any  Collateral Provider, have advised us if there are any requirements in your/their Constitutional Documents, which amend any alterable  provisions  of the Companies Act, particularly relating to your entering into this Agreement, and if applicable your/their power to provide Collateral, and that you/they have taken all necessary steps, fulfilled all formalities and obtained all approvals required  by  your/their  Constitutional  Documents  as well as the Companies Act;
                        </li>
                        <li>
                          all information that you provided to us in connection with this Agreement is in all aspects true, complete, current and accurate, and you are not aware of any material facts or circumstances not disclosed to us which, if disclosed, would adversely affect our decision to enter into this Agreement;
                        </li>
                        <li>
                          if applicable, you will advise us  in writing of any change  to your status as a VAT vendor and/or if your use of the Goods changes from private use to business use or vice versa;
                        </li>
                        <li>you will ensure that you, at all times, comply with, if applicable, the formalities required in terms of your Constitutional Documents, as well as all legislation and regulations applicable to this Agreement and your business activities, including but not limited to, where applicable:
                          <ol>
                            <li>
                              delivering  on  time  to  the  Companies  and  Intellectual  Property Commission, your annual returns together with payment of your yearly fees, in respect of each financial year, in accordance with the provisions  of the  Companies  Act  or  any  other  applicable  laws;
                            </li>
                            <li>
                              anti-money laundering  and  combatting  the financing  of terrorism regulations; and
                            </li>
                            <li>
                              all environmental laws and responsibilities; and/or
                            </li>
                            <li>
                              all   exchange   control    regulations,   rulings   and   requirements applicable to this Agreement, from time to time.
                            </li>
                          </ol>
                        </li>
                      </ol>
                    </li>
                    <li>
                      You must tell us immediately if you are placed under an administration order, placed under debt review, become insolvent, or have  any form of legal disability. On application for insolvency any amount outstanding under this Agreement will immediately become due, owing and payable to us
                    </li>
                    <li>
                      To the  maximum extent permitted by  law, you  hereby indemnify (hold us harmless) against any loss or damage suffered by us as a result of our reliance on any warranty, representation or information given by you in relation to this Agreement.
                    </li>
                  </ol>
                </li>
                <li><span>Default</span>
                  <ol>
                    <li>Default in terms of this Agreement will occur if:
                      <ol>
                        <li>
                          you fail to pay any amount payable to us under this Agreement on the Repayment Due Date;
                        </li>
                        <li>
                          the  Goods  are  lost  damaged  or  destroyed  as  contemplated  in clause 7.3;
                        </li>
                        <li>
                          you fail to comply  with legislation and/or regulations applicable to your activities, this Agreement and the Goods, including but not limited to any environmental laws and responsibilities, anti-money laundering and combatting the financing of terrorism regulations and/or where applicable any company laws; and/or
                        </li>
                        <li>
                          you breach any of the terms and conditions of this Agreement and you fail to remedy the breach within the timeframe provided for in the written notice to do so;
                        </li>
                        <li>
                          you publish a notice of the voluntary surrender of your estate or die, or are placed under administration or debt review or commits an act of insolvency as defined in the Insolvency Act 24 of 1936, and/or has any application or other proceedings brought against you, in terms of which you are sought to be sequestrated or placed under curatorship, whether provisionally or finally, whether voluntarily or compulsory;
                        </li>
                        <li>
                          you compromise or attempt to compromise with your creditors generally or defer payment of debts owing by you to your creditors;
                        </li>
                        <li>
                          any representation, warranty or assurance made or given by you in connection with your application for this Agreement or any information and/or documentation supplied by you is, in our opinion, materially incorrect or false;
                        </li>
                        <li>
                          you generally do or omit to do anything which may affect our rights in terms of this Agreement;
                        </li>
                        <li>
                          you fail to keep the Goods licenced and registered with the relevant authorities; and
                        </li>
                        <li>
                          judgment of a competent court against you, for the attachment of assets or for payment of any amount remains unsatisfied for more than 7 (seven) days after the date on which it is issued.
                        </li>
                      </ol>
                    </li>
                    <li>
                      If you are  in default of this  Agreement, we may give you written notice of such default, requesting you to rectify the default within 10 (ten) Business Days, and propose that you refer this Agreement to a debt  counsellor,  alternative  dispute  resolution  agent,   consumer court or ombud with jurisdiction, with the intent that the Parties resolve any dispute under this Agreement or develop and agree on a plan to bring your Repayments up to date.
                    </li>
                    <li>If you fail to respond to us  or reject our proposals set out in the written notice referred to 19.2, and/or you remain in default for 20 (twenty) Business Days after the date of the written notice referred to in 19.2, we may commence legal proceedings against you to:
                      <ol>
                        <li>
                          claim payment of all Repayments and any other amounts, whether due or not; provided, however, that if you do not make immediate payment we may, notwithstanding the election to claim immediate payment in terms of this sub-clause, claim the relief set out in 19.3.2 below;
                        </li>
                        <li>
                          cancel this Agreement and claim return of the Goods. If we sell the Goods after having  obtained an attachment order (i.e. the Goods are repossessed by us), and the net proceeds of the sale is insufficient to discharge all of your obligations in terms of this Agreement, we may, subject to clauses 9.4.2 to 9.7, commence with further legal proceedings to enforce all of your remaining legal obligations under this Agreement. If we do not obtain possession of the Goods, the value of same will be nil.
                        </li>
                      </ol>
                    </li>
                    <li>
                      We may exercise our rights in terms of this “Default” clause and if you dispute our right to do so, you must continue the Repayments. Our acceptance of such amounts will not affect any of our rights in terms of this Agreement or in law.
                    </li>
                    <li>
                      If  you are  in default  under  this  Agreement,  we  may draw  such default to your attention in writing and suggest that you refer this Agreement to a - debt counsellor; or a dispute resolution agent; or the consumer court; or ombud with jurisdiction. If you have approached a debt counsellor we may, in respect of this Agreement, give notice to terminate the debt  review, at any time, at least 60 (sixty) Business Days after the date on which you applied for the debt review.
                    </li>
                    <li>
                      <span>The above default clauses and default procedure will not apply if you generally do or omit to do anything which may cause us to suffer any loss or damage,  we in any way know or suspect that your Account is being used fraudulently, negligently,  for illegal or for any terrorist activities,  or for any purpose that does not comply with the law or you are involved in any illegal or terrorist activities.</span>
                    </li>
                    <li>
                      <span>In these circumstances we may immediately terminate the Agreement, and call for immediate payment of all amounts owing by  you to us.  If we close, restrict activity or suspend access to your Account for any reason, we will not be legally responsible (liable), directly or indirectly, for any damages arising as a result of such action that you or any third party may suffer, unless such damages are caused by our gross negligence or that of any person acting for or controlled by us.</span>
                    </li>
                    <li>
                      You agree to us using any electronic means available to track and trace the Goods should you be in default under this Agreement.
                    </li>
                  </ol>
                </li>
                <li><span>Cession by the Bank</span>
                  <ol>
                    <li>
                      <span>To the maximum extent permitted by law, you hereby agree that we may, without further notice to you, cede and transfer all or part of our rights and/or delegate all or any part of our obligations under this Agreement, either absolutely or as collateral to any person, even though that cession and/or delegation may result in a splitting of claims against you.</span>
                    </li>
                    <li>
                      <span>You agree that you may not transfer your rights or delegate your obligations under this Agreement unless you have obtained our written consent.</span>
                    </li>
                  </ol>
                </li>
                <li><span>Information sharing</span>
                  <ol>
                    <li>
                      By entering into this Agreement you acknowledge and agree that we may provide any registered credit bureau with - details provided by you  in  this  Agreement;  and/or  details  of  the  conduct  of  your Account; and/or details of any adverse information as defined in the NCA. In respect of such adverse information, we will give you at least 20 (twenty) Business Days’  notice of our intention to provide the credit bureaux with this information; and/or details of the transfer of our rights as a credit provider under this Agreement to another person; and/or any other details as may be required by the NCA or applicable law.
                    </li>
                    <li>
                      Based on their  records, the credit bureaux may  provide a Credit Record and/or a credit score on your creditworthiness to other credit providers. You have the right to contact the credit bureaux to have your Credit Record with it disclosed and to request the correction of inaccurate information. The name(s) and contact details of the credit bureaux will be made available to you on request and shall also be made available on our website.
                    </li>
                    <li>
                      We may provide details to the South African Fraud Prevention Services (SAFPS)  of any conduct on your Account that  gives us reasonable cause to suspect that the Account is being used for improper purposes. The SAFPS may in turn make this information available to other members of the SAFPS if they carry out credit or other checks on your name
                    </li>
                    <li>
                      At the request of any Surety/Guarantor, you agree that we may provide them with a copy of this Agreement, together with any amendments thereto, and/or details of the conduct of your Account.
                    </li>
                  </ol>
                </li>
                <li><span>Addresses for notices</span>
                  <ol>
                    <li>
                      You choose, as the address for the serving of legal notices in terms of this Agreement (notice address), your address set out in Part A.
                    </li>
                    <li>
                      Any other notice or communication required or permitted to be given in respect of the provisions of this Agreement will be valid and effective only if in writing and sent to your notice address  or the telefax number, email address or postal address provided in your application for finance, or any address advised in terms of clause 22.3 below, provided that the documents to be delivered in respect of legal proceedings in connection with this Agreement may only be served at your notice address.
                    </li>
                    <li>
                      You must give us written notice to change your notice address, the address of the premises on which the Goods in respect of this Agreement are ordinarily kept, in the event of a sub-lease, the name and address of any other person to whom possession of the Goods has been transferred, postal address, telefax number or email address. The change will be effective on the 10th (tenth) Business Day  after  receipt  of  the  notice.  Any  notice  -  sent  by  prepaid registered post will be deemed to have been received on the 5th (fifth) Business  Day after posting; or sent by ordinary mail will be deemed to have been received on the 7th (seventh) Business Day after posting; or delivered  by hand will be deemed to have been received on the day of delivery; or sent by telefax or email will be deemed to have been received on the 1st (first) Business Day after the date it was sent.
                    </li>
                    <li>
                      Despite anything to the contrary set out in this Addresses for notices clause, a written notice or communication actually received by you will be an adequate written notice  or communication to you even will be an adequate written notice or communication to you even though it was not sent to or delivered to your notice address, postal address, telefax number or email address. You hereby agree that where the post office does not effect street deliveries at your notice address, we may send any notices in terms of this Agreement to your postal address.
                    </li>
                    <li>
                      We choose the address set out on page 1 of Part A as the address at which all notices required to be delivered in terms of this Agreement, must be delivered by you (our notice address).
                    </li>
                  </ol>
                </li>
                <li><span>General</span>
                  <ol>
                    <li>
                      This Agreement is subject to the Exchange Control regulations in force from time to time in the Republic of South Africa and the requirements and directions of the Reserve Bank.
                    </li>
                    <li>You declare, warrant, represent and undertake to the Bank on the Signature Date hereof and on each date upon which this Agreement is in place, that:
                      <ol>
                        <li>
                          you are not involved in any illegal or terrorist activities; and
                        </li>
                        <li>
                          none   of  your  accounts   held  with   the   Bank  are  being  used fraudulently,  negligently, for illegal or terrorist activities, or for any purpose that does not comply with any law.
                        </li>
                      </ol>
                    </li>
                    <li>
                      You hereby indemnify and hold the Bank and/or the Group harmless against any actions, proceedings, claims and/or demands that may be brought against the Bank and/or the Group and all losses, damages, costs and expenses which the Bank may incur or sustain, in connection with or arising out of the breach of any warranties as set out in clause 23.2 above. Payment under the above indemnity shall be made by you on demand by the Bank or the Group. The provisions of this clause constitute a stipulation for the benefit of the Group which is not a direct party to this Agreement, capable of acceptance by the Group at any time and in any manner permitted by law.
                    </li>
                    <li>
                      If your date for Repayment or the charging of interest, costs, fees or charges does not fall on a Business Day, the item(s) will be processed on the 1st (first) Business Day after that day.
                    </li>
                    <li>
                      <span>Where the value of the Goods must be determined for any purpose related to this Agreement, and in our opinion we are required to appoint an assessor/valuator, you authorise us to appoint an assessor/valuator of our choice. You agree and undertake to accept such assessment/valuation.</span>
                    </li>
                    <li>
                      <span>Save  as  provided  for in  clauses  14.3 and  14.4 above,  any agreed changes  to this Agreement will be made in writing in paper based form and  signed by  both you  and us, or if the changes are recorded telephonically, we will provide you with written confirmation of the change in paper based form. We will deliver to you a document reflecting the agreed amendment, no later  than  20  (twenty)  Business Days  after  the date  of the agreed  change to  this Agreement.  Unless specifically  stated and agreed in writing, no amendment to this Agreement will create a new agreement. This requirement will only be satisfied if  such amendment or variation  is made  in a  written,  paper based form. The provisions of the Electronic Communications and Transactions Act 25 of 2002 do not apply to this clause.</span>
                    </li>
                    <li>
                      Any  translated  version  of  this  Agreement  translated  from  this English   version   will   be   provided   to you,   upon   request,   for information purposes only. While the translated version of the Agreement describes the rights and obligations contained in this Agreement please note that this English version of the Agreement signed  by you constitutes the legally binding Agreement between us. Therefore, any enforcement procedures in respect of the Agreement will be based on this English version.
                    </li>
                    <li>
                      This Agreement will be governed by and interpreted in accordance with  the  laws  of  the  Republic  of South  Africa  and  you  hereby consent in terms of section 45 of the Magistrate’s   Court Act 32 of 1944 of to us taking legal steps to enforce any of our rights in terms of  this  Agreement,  to  the  jurisdiction  of any Magistrate’s   Court having jurisdiction in the area in which you reside or work, despite the amount involved. You do not consent to the jurisdiction of the High Court if the Magistrate’s Court has concurrent jurisdiction.
                    </li>
                    <li>
                      To the maximum extent permitted by law, any special consideration we may give you will not be seen as a waiver of any of our rights under this Agreement or in any way affect any of our rights against you.
                    </li>
                    <li>
                      On application for insolvency proceedings any amounts outstanding under  this  Agreement  will  immediately  become  due, owing  and payable to us.
                    </li>
                    <li>
                      A certificate signed  by any of our  managers, whose appointment need not be proved, specifying the amount which you owe to us and stating that such amount is due, owing and payable by you to us, will on its mere production be sufficient proof of any amount due and/or owing by you in terms of this Agreement, unless the contrary is proved.
                    </li>
                    <li>
                      You have the right to - resolve any complaint by referring the matter to a dispute resolution agent, the consumer court or the ombud with jurisdiction; and/or in respect  of any alleged contravention of the CPA or NCA, file a complaint with the National Consumer Commission in respect of the CPA; or National Credit Regulator in respect of the NCA; and/or make an application to the  Tribunal for an  order resolving  a  dispute  over information  held  by  a  credit bureau; and/or  an order compelling  the  delivery of a  Statement and/or  review  of  a  Statement;  and/or  permission  to  bring  a complaint directly before the Tribunal; and/or an order allowing late filing.
                    </li>
                    <li>
                      The contact details of the Credit Bureau, National Credit Regulator, National Consumer Commission, Tribunal and Banking Ombud are available at your request and are also available on our website.
                    </li>
                    <li>
                      To the extent that this Agreement, or the Goods or services which are the subject of this Agreement, are governed by or subject to the CPA, no provision of this Agreement is intended to contravene the applicable provisions of the CPA. All provisions of this Agreement will be deemed to be qualified to the extent required in order to ensure compliance with the applicable provisions of the CPA and this Agreement must be interpreted and applied accordingly.
                    </li>
                    <li>
                      If  any  of  the  clauses  (or  any  portion  of  the  clauses)  in  this Agreement are found to be invalid, illegal or unenforceable this will not  affect  the  remaining  clauses  in  this  Agreement  which  will continue with full force and effect.
                    </li>
                    <li>
                      You should  contact us if you need further explanation of anything related to, or referred to in this Agreement. You may contact us at our   Customer   Contact  Centre,  email  us  at <span>vaf@standardbank.co.za</span> or speak to a consultant at any Standard Bank branch or at the Vehicle and Asset Finance call centre at <span>0860 000 000</span>.
                    </li>
                    <li>
                      This  Agreement  constitutes  the  entire  Agreement  between  the Parties in relation to the subject matter thereof. Each term of this Agreement is separate from the other. Neither Party shall be bound by any express, tacit or implied term, representation, warranty promise or the like not recorded herein. This Agreement supersedes and  replaces  all   prior  commitments,  undertakings  or representations, whether oral or written, between the Parties in respect of the subject matter hereof.
                    </li>
                  </ol>
                </li>
                <li><span>Data Protection</span>
                  <ol>
                    <li>
                      You consent to us collecting  your Personal Information from you and where lawful and  reasonable, from public sources  for credit, fraud and compliance purposes as well as the purposes set out below.
                    </li>
                    <li>
                      If you give us Personal  Information about or on behalf of another person (including, but not limited to, account signatories, shareholders, principal executive officers,  trustees and beneficiaries), you confirm that you are authorised to: (a) give us the Personal Information; (b) consent on their behalf to the Processing of their Personal Information, specifically any cross-border transfer of Personal Information into and outside the country where the products or  services  are  provided;  and  (c)  receive any  privacy notices on their behalf.
                    </li>
                    <li>You consent to us Processing your Personal Information:
                      <ol>
                        <li>
                          to provide products and services to you in terms of this Agreement and any other products and services for which you may apply;
                        </li>
                        <li>
                          to  carry  out  statistical  and  other  analyses  to  identify  potential markets  and  trends,  evaluate  and  improve  our  business  (this includes improving existing and developing new products and services);
                        </li>
                        <li>
                          in countries outside the country where the products or services are provided. These countries may not have the same data protection laws as the country where the products or services  are provided. Where  we can,  we  will ask  the  receiving  party to agree  to our privacy policies;
                        </li>
                        <li>
                          by sharing your Personal Information with our third-party service providers, locally and outside the country where the products or services are provided. We ask people who provide services to us to agree to the our privacy policies if they need access to any Personal Information to carry out their services;
                        </li>
                        <li>
                          within the Group.
                        </li>
                      </ol>
                    </li>
                    <li>
                      You consent to us disclosing your Personal Information to Collateral Providers.
                    </li>
                    <li>
                      You will find our Processing practices in the Group and our privacy statements.   These   statements  are  available   on  the   Group’s websites or on request.
                    </li>
                    <li>
                      If you are unsure about your tax or legal position because your Personal  Information is Processed in countries  other than where you live or conduct business, you should get independent advice.
                    </li>
                  </ol>
                </li>
                <li><span>Sanctions</span>
                  <ol>
                    <li>The Customer must not:
                      <ol>
                        <li>
                          use the Account or any of the Customer’s  bank accounts with the Bank to finance any Sanctioned Entity;
                        </li>
                        <li>
                          make the proceeds of the Account or any of the Customer’s  bank accounts with the Bank available to any person  who may use or intends  to  use  the  proceeds  to  finance  a  Sanctioned  Entity’s activities;
                        </li>
                        <li>
                          act in a way that benefits a Sanctioned Entity;
                        </li>
                        <li>
                          be involved in any Prohibited Activities; or
                        </li>
                        <li>
                          use any product or service provided by the Group (excluding Liberty Holdings Limited and its subsidiaries) for any Prohibited Activities.
                        </li>
                      </ol>
                    </li>
                    <li>You  warrant  that  neither  you,  nor  any  other  member  of  the Customer’s Group are -
                      <ol>
                        <li>
                          a Sanctioned Entity; or
                        </li>
                        <li>
                          being investigated for any activities relating to Sanctions.
                        </li>
                      </ol>
                    </li>
                    <li>
                      The Customer must let us know immediately in writing if you or any other member of the Customer’s  Group are being investigated for any activities relating to Sanctions.
                    </li>
                    <li>The Customer indemnifies  the Bank against any fines, penalties, losses, damages, costs, actions,  proceedings, claims or demands <span>(Losses)</span> which the  Group (excluding Liberty Holdings Limited and its subsidiaries) may suffer because:
                      <ol>
                        <li>
                          any funds are seized or withheld by any Sanctioning Body or any other third party (including by us); or
                        </li>
                        <li>
                          you breach this clause 25.
                        </li>
                      </ol>
                    </li>
                    <li>If the Bank know or suspects that you are in breach of this clause 25 or you or any other member of the Customer’s Group are about to become subject to Sanctions, the Bank can immediately, in its sole discretion -
                      <ol>
                        <li>
                          close, restrict activity or suspend access to the Account or any of your bank accounts with the Bank and any other product or service the Bank provides you; and/or
                        </li>
                        <li>
                          cancel this Agreement and/or any other relationship which the Bank has with you.
                        </li>
                      </ol>
                    </li>
                    <li>
                      The Bank is not liable to you for any Losses you suffer if the Bank cancels  this Agreement or any other relationship which the Bank has with you, for the reasons set out in this clause 25.
                    </li>
                  </ol>
                </li>
              </ol>
            </div>
          </div>
        </div>
      </div>
      <!-- end VdBlock -->
    </div>
    <!-- end DetailsPage__Content -->
  </div>
  <!-- end Container -->
</template>

<script>
var ScrollTo = require("vue-scrollto");

export default {
  name:"TermsConditions",
  watch: {
    $route() {
      this.selectTab();
    }
  },
  mounted() {
    this.$nextTick(() => {
      $("ul.nav-tabs li a").click(function() {
        $("ul.nav-tabs li.active").removeClass("active");
        $(this)
          .parent("li")
          .addClass("active");
      });
    });

    this.selectTab();

    if(this.$route.params.section) {
      ScrollTo.scrollTo(this.$route.params.section)
    }
  },
  methods: {
    selectTab() {
      $("ul.nav-tabs li.active").removeClass("active");
      $(".tab-pane").removeClass("active");
      $(this.$route.hash).addClass("active")
      $("a[href='"+this.$route.hash+"']")
        .parent("li")
        .addClass("active");
    }
  }
};
</script>

<style>
ol {
  list-style-type: none;
  counter-reset: item;
  margin: 0;
  padding: 0;
}

ol > li {
  display: table;
  counter-increment: item;
  margin-bottom: 0.6em;
}

ol > li:before {
  content: counters(item, ".") ". ";
  display: table-cell;
  padding-right: 0.6em;
}

li ol > li {
  margin: 0;
}

li ol > li:before {
  content: counters(item, ".") " ";
}

.Table
{
    display: table;
    width: 100%;
}
.Title
{
    display: table-caption;
    font-weight: bold;
    font-size: larger;
}
.Heading
{
    display: table-row;
    font-weight: bold;
}
.Row
{
    display: table-row;
}
.Cell
{
    display: table-cell;
    font-size: 22px;
    font-weight: 300;
    color: #0A2240;
    line-height: 34px;
    margin-bottom: 18px;
}
.Cell1
{
    display: table-cell;
    width: 30%;
    font-size: 22px;
    font-weight: 300;
    color: #0A2240;
    line-height: 34px;
    margin-bottom: 18px;
}
</style>
